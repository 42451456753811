import React, { useContext } from "react";
import MediaLiveService from "./MediaLiveService";
import { Box, Typography } from "@mui/material";
import { MyContext } from "../../context/Context";
import LoaderSkeleton from "../Utility/LoaderSkeleton";

export default function Medialive(filter = null) {
  const { state } = useContext(MyContext);

  const medialiveServices = state.status.mix?.medialive;
  const hasServices =
    medialiveServices &&
    Object.keys(medialiveServices).some(
      (region) => medialiveServices[region].length > 0
    );

  return (
    <div>
      <Box
        display="flex"
        flexWrap={"wrap"}
        justifyContent={"left"}
        alignItems={"center"}
        gap="10px"
      >
        {hasServices ? (
          Object.entries(medialiveServices).map(([region, services], index) => (
            <React.Fragment key={index}>
              {/* Sort services by event_name */}
              {services
                .slice() // Create a copy to avoid mutating state
                .sort((a, b) =>
                  a.event_name.localeCompare(b.event_name, "en", {
                    numeric: true,
                  })
                )
                .map((service, svcIndex) => (
                  <div key={svcIndex}>
                    <MediaLiveService
                      service={service}
                      region={region}
                      filter={filter}
                    />
                  </div>
                ))}
            </React.Fragment>
          ))
        ) : (
          <>
            {!state.status.mix ? (
              <LoaderSkeleton></LoaderSkeleton>
            ) : (
              <Typography style={{ margin: "20px" }}>
                No services scheduled...
              </Typography>
            )}
          </>
        )}
      </Box>
    </div>
  );
}
