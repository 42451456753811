import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import JsonImporter from "../../Utility/JsonImporter";
const AddSpalkProfileForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);
  const [selectedGraphicsProfile, setSelectedGraphicsProfile] = useState("");
  const [selectedGraphicsTypeProfile, setSelectedGraphicsTypeProfile] =
    useState("");
  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    region: "us-east-1",
    rundown: [],
    graphics: [],
    audio_config: "",
    start_offset: "",
    stop_offset: "",
    privacy_setting: "3",
  });
  const [selectedAbility, setSelectedAbility] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleAddRundownTask = (taskToAdd) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rundown: [...prevFormData.rundown, taskToAdd],
    }));
  };
  const handleAddGraphicsProfile = (graphics_profile) => {
    setSelectedGraphicsProfile(graphics_profile);
    setFormData({
      ...formData,
      graphics:
        Array.isArray(graphics_profile) && graphics_profile.length === 0
          ? [] // If None is selected, keep graphics as an empty array.
          : [graphics_profile],
    });
  };

  const handleRemoveGraphicsProfile = (graphics_profile_to_remove) => {
    setFormData({
      ...formData,
      graphics: formData.graphics.filter(
        (graphics_profile) => graphics_profile !== graphics_profile_to_remove
      ),
    });
  };

  const handleClose = () => {
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };

  const handleSubmit = () => {
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && formData[key] === "") {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });

    if (isValid) {
      onClose();
      setError("");
      onAdd(formData);
      setFormData({
        uid: uuidv4(),
        alias: "",
        region: "us-east-1",
        rundown: [],
        graphics: [],
        audio_config: "",
        start_offset: "",
        stop_offset: "",
        privacy_setting: "3",
      });
    }
  };

  const handleRemoveRundownTask = (index) => {
    setFormData({
      ...formData,
      rundown: formData.rundown.filter((_, i) => i !== index),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {" "}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1">Add New Spalk Profile </Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          margin="normal"
          name="uid"
          label="Uid"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          name="audio_config"
          label="Audio Configuration"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.audio_config}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          name="start_offset"
          label="Start Offset"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.start_offset}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          name="stop_offset"
          label="Stop Offset"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.stop_offset}
          onChange={(e) => handleChange(e)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="region-select-label">Region</InputLabel>
          <Select
            labelId="region-select-label"
            name="region"
            value={formData.region}
            onChange={handleChange}
            label="Region"
          >
            <MenuItem value={"us-east-1"}>us-east-1</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel id="graphics-profile-select-label">
            Graphics Profile
          </InputLabel>
          <Select
            labelId="graphics-profile-select-label"
            value={selectedGraphicsProfile}
            onChange={(e) => handleAddGraphicsProfile(e.target.value)}
            label="Graphics Profile"
          >
            <MenuItem key={"default_value"} value={[]}>
              None
            </MenuItem>
            {state.database.graphics_profiles.map((profile) => (
              <MenuItem key={profile.uid} value={profile}>
                {profile.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="spalk-abilities-select-label">
            Spalk Rundown Tasks
          </InputLabel>
          <Select
            labelId="spalk-abilities-select-label"
            value={selectedAbility}
            onChange={(e) => setSelectedAbility(e.target.value)}
            label="Spalk Rundown Tasks"
          >
            {state.database.spalk_abilities.map((ability) => (
              <MenuItem key={ability.uid} value={ability}>
                {ability.alias}
              </MenuItem>
            ))}
          </Select>
          {selectedAbility ? (
            <TextField
              autoFocus
              margin="normal"
              name="alias"
              label="Execution Offset"
              type="text"
              fullWidth
              variant="outlined"
              value={selectedAbility.execution_offset}
              onChange={(e) => {
                setSelectedAbility({
                  ...selectedAbility,
                  execution_offset: e.target.value,
                });
              }}
            />
          ) : null}
          <Button
            variant="contained"
            onClick={() => {
              handleAddRundownTask(selectedAbility);
            }}
            style={{ marginTop: "10px" }}
            disabled={selectedAbility === ""}
          >
            Add Rundown Task
          </Button>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              marginTop: "40px",
            }}
          >
            {formData.rundown.map((ability, index) => (
              <Chip
                key={ability.uid + index}
                label={ability.alias + " : " + ability.execution_offset}
                onDelete={() => {
                  handleRemoveRundownTask(index);
                }}
                color="primary"
              />
            ))}
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSpalkProfileForm;
