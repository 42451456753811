import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Paper,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { fetchThumbnailApi } from "./fetchThumbnailApi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Thumbnail({
  srtUrl,
  disable = false,
  autoLoad = false,
  endpoint = "",
  width = 720, // ✅ Allow parent to control width (default: 720px)
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ffmpegErrorDetails, setFfmpegErrorDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchThumbnail = async () => {
    if (!srtUrl || typeof srtUrl !== "string" || !srtUrl.trim()) {
      setError("Please enter a valid SRT stream URL.");
      return;
    }

    setLoading(true);
    setError(null);
    setFfmpegErrorDetails(null);

    try {
      const { success, data, error } = await fetchThumbnailApi(srtUrl);
      if (!success) {
        setError(error || "Failed to fetch thumbnail");
        console.log(error);
        // ✅ Ensure `error` contains details before setting state
        if (error && error.ffmpeg_output) {
          setFfmpegErrorDetails(error);
        }
        return;
      }

      setImageSrc(data.thumbnail);
      setMetadata(data.metadata);
    } catch (err) {
      console.error("Thumbnail Fetch Error:", err);
      setError("Unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Automatically fetch on mount if `autoLoad` is enabled
  useEffect(() => {
    if (autoLoad && srtUrl) {
      fetchThumbnail();
    }
  }, [srtUrl, autoLoad]);

  return (
    <div style={{ textAlign: "left", marginTop: 0, width: "100%" }}>
      {/* 🔹 Thumbnail Display or Error Placeholder */}
      {!imageSrc && !metadata ? (
        <Card
          style={{
            width: width,
            maxWidth: "100%",
            height: "auto",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            color: "#888",
          }}
        >
          {loading ? (
            <Box
              display="flex"
              p={10}
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="warning">Loading...</Typography>
            </Box>
          ) : error ? (
            <Box p={10}>
              <Typography color="error" gutterBottom>
                {error.error}
              </Typography>
            </Box>
          ) : (
            <Typography p={10} color="warning">
              No Thumbnail or Metadata...
            </Typography>
          )}
        </Card>
      ) : (
        <>
          {/* ✅ Display Thumbnail if Available */}
          <Box
            justifyContent={"flex-start"}
            display="flex"
            flexDirection={"column"}
            width="100%"
          >
            {imageSrc && (
              <img
                src={imageSrc}
                alt="Thumbnail"
                style={{
                  width: width,
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: 5,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  display: "block",
                  marginBottom: 0,
                }}
              />
            )}

            {metadata && (
              <Accordion
                sx={{
                  width: "100%",
                  maxWidth: width,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", fontWeight: "bold" }}
                  >
                    Metadata: {endpoint}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    component="pre"
                    sx={{
                      fontSize: "14px",
                      whiteSpace: "pre-wrap",
                      margin: 0,
                    }}
                  >
                    {metadata}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </>
      )}

      {/* 🔹 Buttons Section */}
      <Box display="flex" gap={2}>
        <Tooltip
          title={
            disable
              ? "Max connections reached or destination in caller mode..."
              : ""
          }
          arrow
        >
          <span>
            <Button
              style={{
                borderTopRightRadius: "0px",
                borderTopLeftRadius: "0px",
                minWidth: "70px",
                marginLeft: "40px",
              }}
              disabled={disable}
              variant="contained"
              color="primary"
              onClick={fetchThumbnail}
            >
              {loading ? (
                <CircularProgress size={25} color="inherit"></CircularProgress>
              ) : (
                <FileDownloadIcon />
              )}
            </Button>
          </span>
        </Tooltip>

        {ffmpegErrorDetails && (
          <Button
            style={{
              marginLeft: "40px",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
              minWidth: "70px",
            }}
            variant="contained"
            color="error"
            onClick={() => setDialogOpen(true)}
          >
            <ErrorOutlineIcon />
          </Button>
        )}
      </Box>

      {/* 🔹 FFmpeg Error Details Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>FFmpeg Error Details</DialogTitle>
        <DialogContent>
          <Typography
            component="pre"
            sx={{ whiteSpace: "pre-wrap", fontSize: "12px" }}
          >
            {ffmpegErrorDetails?.ffmpeg_output ||
              "No detailed FFmpeg output available"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="secondary"
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
