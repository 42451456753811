// src/utilities/DistributionEditor.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Alert,
} from "@mui/material";
import { useApiRequest } from "../Utility/useAPIRequest";

// Helper: returns the port number (as a string) for a given distribution.
const getPortForDistribution = (distribution) => {
  switch (distribution) {
    case "A":
    case "J":
    case "S":
      return "1";
    case "B":
    case "K":
    case "T":
      return "2";
    case "C":
    case "L":
    case "U":
      return "3";
    case "D":
    case "M":
    case "V":
      return "4";
    case "E":
    case "N":
    case "W":
      return "5";
    case "F":
    case "O":
    case "X":
      return "6";
    case "G":
    case "P":
    case "Y":
      return "7";
    case "H":
    case "Q":
    case "Z":
      return "8";
    case "I":
    case "R":
      return "9";
    default:
      console.error("No distribution mapping found for", distribution);
      throw new Error(
        "No distribution mapping found for distribution: " + distribution
      );
  }
};

const DistributionEditor = ({
  tableName,
  currentItem,
  availableDistributions,
  onSaveSuccess,
  onCancel,
}) => {
  const { makeApiRequest } = useApiRequest();
  const [selectedDistributions, setSelectedDistributions] = useState(
    currentItem.distributions || []
  );
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // Normalize value into an array
    const newValue = typeof value === "string" ? value.split(",") : value;
    // Check for conflict: for any newly added distribution (i.e. not already in selectedDistributions)
    const newlyAdded = newValue.filter(
      (dist) => !selectedDistributions.includes(dist)
    );
    for (let dist of newlyAdded) {
      if (
        selectedDistributions.some(
          (s) => getPortForDistribution(s) === getPortForDistribution(dist)
        )
      ) {
        setError(
          `Conflict: Cannot add ${dist} because it shares the same port with an already selected distribution.`
        );
        return;
      }
    }
    setError("");
    setSelectedDistributions(newValue);
  };

  const handleRemove = (value) => {
    setSelectedDistributions(
      selectedDistributions.filter((dist) => dist !== value)
    );
    setError("");
  };

  const handleSave = async () => {
    const updatedItem = {
      ...currentItem,
      distributions: selectedDistributions,
    };

    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        command: "command_any_table",
        obj: { command: "put", item: updatedItem, table_name: tableName },
      }),
    };

    const { success, error } = await makeApiRequest(
      endpoint,
      options,
      tableName
    );
    if (success) {
      onSaveSuccess && onSaveSuccess(updatedItem);
    } else {
      console.error("Failed to save data:", error);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginTop: 2,
      }}
    >
      {/* Display selected chips outside of the select */}
      <Box sx={{ mb: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {selectedDistributions.map((value) => (
          <Chip
            key={value}
            label={value}
            size="small"
            onDelete={() => handleRemove(value)}
            variant="outlined"
          />
        ))}
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <FormControl sx={{ minWidth: 300 }}>
        <InputLabel id="select-multiple-distribution-label">
          Select Distributions
        </InputLabel>
        <Select
          labelId="select-multiple-distribution-label"
          multiple
          value={selectedDistributions}
          onChange={handleChange}
          input={<OutlinedInput label="Select Distributions" />}
          // Do not render selected values here, as they are displayed above.
          renderValue={() => ""}
        >
          {availableDistributions
            .slice() // Create a shallow copy
            .sort((a, b) => a.distribution.localeCompare(b.distribution))
            .map((d) => {
              // Disable if not already selected and adding it would conflict with an existing selection.
              const isDisabled =
                !selectedDistributions.includes(d.distribution) &&
                selectedDistributions.some(
                  (s) =>
                    getPortForDistribution(s) ===
                    getPortForDistribution(d.distribution)
                );
              return (
                <MenuItem
                  key={d.uid}
                  value={d.distribution}
                  disabled={isDisabled}
                >
                  {d.alias}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      {/* Buttons at bottom right */}
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}>
        <Button onClick={onCancel} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </Box>
    </Box>
  );
};

DistributionEditor.propTypes = {
  tableName: PropTypes.string.isRequired,
  currentItem: PropTypes.object.isRequired,
  availableDistributions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSaveSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DistributionEditor;
