import React, { useState, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button, Paper } from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import NabletDetails from "./NabletDetails";
import NabletConfig from "./NabletConfig";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DistributionsBox from "../Utility/DistributionsBox";

export default function Nablet({ nablet, index }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const [loading, setLoading] = useState(false);
  const [statusInfo, setStatusInfo] = useState(null);

  const handleStart = async (nablets) => {
    try {
      await requestConfirm("Are you sure you want to start this instance?");
      let url = process.env.REACT_APP_START_NABLETS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ nablets }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleStop = async (nablets) => {
    try {
      await requestConfirm("Are you sure you want to stop this instance?");
      let url = process.env.REACT_APP_STOP_NABLETS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ nablets }),
        },
        onSuccessfulModification
      );
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getNabletStatusInfo = async () => {
    setLoading(true);
    try {
      let url = process.env.REACT_APP_STATUS_URL;
      const result = await makeApiRequest(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          command: "get_nablet_status",
          input: { nablets: [nablet] },
        }),
      });
      console.log(result);
      setStatusInfo(result.data.response); // Set the response as statusInfo
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const nabletStatus = state.status.nablets?.find((status) =>
    status.InstanceStatuses.some(
      (instance) => instance.InstanceId === nablet["aws_instance_id"]
    )
  );

  const instanceState = nabletStatus ? (
    <Typography>
      {nabletStatus.InstanceStatuses[0].InstanceState.Name}
    </Typography>
  ) : (
    <LinearProgress style={{ width: "50px" }} />
  );

  return (
    <div>
      {ConfirmDialog}
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <ComputerIcon
              color={
                nabletStatus
                  ? nabletStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                    ? "primary"
                    : "secondary"
                  : "action"
              }
            />
            <Typography style={{ minWidth: "270px" }}>
              {nablet["name"]}
            </Typography>
            <Box sx={{ display: "flex" }}>{instanceState}</Box>
            {nablet["distributions"] && (
              <DistributionsBox distributions={nablet["distributions"]} />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "10px",
                borderTopRightRadius: "0px",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleStart([nablet])}
            >
              Start
            </Button>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopLeftRadius: "0px",
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleStop([nablet])}
              disabled={
                !(
                  nabletStatus &&
                  nabletStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                )
              }
            >
              Stop
            </Button>
          </div>
          <Paper style={{ padding: "20px", marginBottom: 20 }}>
            <NabletDetails nablet={nablet} statusInfo={statusInfo} />
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginTop: "50px",
                marginLeft: "10px",
              }}
              variant="contained"
              color="primary"
              onClick={getNabletStatusInfo}
              disabled={
                !(
                  nabletStatus &&
                  nabletStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                )
              }
            >
              {loading ? "Loading..." : "Load Status"}
            </Button>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginTop: "50px",
                marginLeft: "40px",
              }}
              variant="contained"
              color="primary"
              onClick={getNabletStatusInfo}
              disabled={true}
            >
              <RadioButtonCheckedIcon
                style={{ marginRight: "5px" }}
              ></RadioButtonCheckedIcon>
              Create Recording
            </Button>
            {loading && <LinearProgress />}
            {/* Display NabletConfig with the statusInfo if available */}
            {statusInfo && (
              <NabletConfig configs={statusInfo} nablet={nablet} />
            )}
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
