import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import Flow from "./Flow"; // Ensure Flow is correctly imported
import NewFlow from "./NewFlow";

export default function Flows({ selectedEventId, config }) {
  const [selectedTab, setSelectedTab] = useState(0); // Track selected gateway tab

  // Flatten and sort gateways before assigning tabIndex
  const sortedGateways =
    config?.exchange_configs
      ?.flatMap((exchange) =>
        exchange.api_config?.gateways?.map((gateway) => ({
          gateway,
        }))
      )
      .sort((a, b) => {
        if (a.gateway.path === "main") return -1;
        if (b.gateway.path === "main") return 1;
        if (a.gateway.path === "backup") return -1;
        if (b.gateway.path === "backup") return 1;
        return 0;
      }) || [];

  // Assign tabIndex correctly *after* sorting
  const gatewaysList = sortedGateways.map((item, index) => ({
    ...item,
    tabIndex: index, // Now `tabIndex` matches sorted order
  }));

  return (
    <div style={{ marginTop: "10px" }}>
      {/* Tabs for selecting a gateway */}
      {gatewaysList.length > 0 && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            textColor="primary"
            indicatorColor="transparent"
          >
            {gatewaysList.map(({ gateway, tabIndex }) => (
              <Tab
                key={`tab-${tabIndex}`}
                label={`Gateway ${gateway.path}`}
                sx={{
                  borderBottom:
                    selectedTab === tabIndex
                      ? "3px solid #1976d2"
                      : "3px solid transparent", // Custom blue border
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  transition: "border 0.2s ease-in-out", // Smooth transition
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}

      {/* Display only the selected gateway's Flow */}
      {config && (
        <div style={{ marginTop: "0px" }}>
          {gatewaysList?.map(({ gateway, tabIndex }) =>
            selectedTab === tabIndex ? (
              <div key={`flow-container-${tabIndex}`}>
                <Flow
                  key={`flow-${tabIndex}`}
                  config={config}
                  gateway={gateway}
                  gateway_index={tabIndex}
                ></Flow>
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
}
