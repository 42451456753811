import React, { useState, useContext } from "react";
import { MyContext } from "../../context/Context";
import FlowEventSelector from "./FlowEventSelector";
import Flows from "./Flows";

export default function FlowManager() {
  const { state } = useContext(MyContext);
  const [selectedEventId, setSelectedEventId] = useState("");

  // Find the selected event and extract its config
  const selectedEvent = state.database?.events?.find(
    (ev) => ev.uid === selectedEventId
  );
  const config = selectedEvent ? selectedEvent.config : null;

  return (
    <div>
      <FlowEventSelector
        events={state.database.events}
        selectedEventId={selectedEventId}
        onSelectEvent={setSelectedEventId}
      />
      <Flows selectedEventId={selectedEventId} config={config} />
    </div>
  );
}
