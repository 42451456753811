import React, { useState, useEffect, useRef, useContext } from "react";
import ReactFlow, { Controls, Background, Position } from "react-flow-renderer";
import { useTheme } from "@mui/material/styles";
import { Button, Box, Typography } from "@mui/material";
import Gateway from "../Infrastructure/Gateway";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import ThumbnailsDialog from "../Utility/ThumbnailsDialog";
import { MyContext } from "../../context/Context";
import { Tooltip } from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import copy icon
import GoToTopButton from "../Utility/GoToTopButton";

const Flow = ({ config, gateway, gateway_index }) => {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const theme = useTheme();
  const reactFlowInstance = useRef(null);
  const [fitViewTriggered, setFitViewTriggered] = useState(false);
  const [thumbnailUrls, setThumbnailUrls] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [copied, setCopied] = useState(false); // Add this inside the Flow component

  const gatewayStatus = state.status?.gateways?.find((gateway_status) =>
    gateway_status.InstanceStatuses.some(
      (instance) => instance.InstanceId === gateway["aws_instance_id"]
    )
  );

  const parseWorkflowData = (data) => {
    const parsedNodes = [];
    const parsedEdges = [];
    const mixesToUpdate = [];
    const layerSpacingX = 400;
    const nodeSpacingY = 100;
    const takerOffsetX = 200;
    const takerSpacingY = 60;
    const mixOffsetX = 600;
    const destinationOffsetX = 200;
    const recOffsetX = 300;

    const sourceRoutes = {};
    const takersByRoute = {};

    // 1. Create source route nodes and takers for each route with auto-adjusted spacing
    let overall_source_offset = 0;
    data.exchange_configs.forEach((exchange, exchangeIndex) => {
      exchange.api_config?.routes.forEach((route, routeIndex) => {
        const routeNodeId = `${route.route_uid}`;
        const routePosX = exchangeIndex * layerSpacingX;

        // Calculate additional spacing based on previous routes
        let additional_source_offset = 0;

        if (routeIndex > 0) {
          let prev_route = exchange.api_config?.routes[routeIndex - 1];
          const prevRouteId = `${prev_route.route_uid}`;
          const prevTakers = takersByRoute[prevRouteId];
          const prevTakersLength = prevTakers.length;
          additional_source_offset = prevTakersLength + overall_source_offset;
        }

        const routePosY = nodeSpacingY + additional_source_offset;
        overall_source_offset =
          overall_source_offset + route.destinations.length * takerSpacingY;

        // Create the source route node
        parsedNodes.push({
          id: routeNodeId,
          data: { label: route.route_name },
          position: { x: routePosX, y: routePosY },
          style: {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.contrastText,
            padding: 10,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        });

        sourceRoutes[route.route_uid] = {
          id: routeNodeId,
          xPosition: routePosX,
          yPosition: routePosY,
          index: routeIndex,
        };

        // Create takers to the right of the source route node
        takersByRoute[route.route_uid] = []; // Initialize takers for this route
        route.destinations.forEach((destination, destIndex) => {
          const takerNodeId = `${routeNodeId}-taker-${destIndex}`;
          const takerPosX = routePosX + takerOffsetX;
          const takerPosY = routePosY + destIndex * takerSpacingY;

          parsedNodes.push({
            id: takerNodeId,
            data: {
              label: (
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography ml={2}>
                    {destination.srt_name + ": " + destination.srt_port}
                  </Typography>
                  <Box display="flex" gap="5px">
                    {destination.srt_mode === "listener" ? (
                      <Tooltip
                        title={
                          (gatewayStatus &&
                            gatewayStatus.InstanceStatuses[0].InstanceState
                              .Name !== "running") ||
                          destination.srt_connection_limit == "1"
                            ? "Disabled: Instance is not running or low connection limit"
                            : "Open Thumbnail"
                        }
                        arrow
                      >
                        <span>
                          {" "}
                          {/* Wrap the button inside a span to support disabled tooltip */}
                          <Button
                            variant={"outlined"}
                            pl={6}
                            pr={6}
                            disabled={
                              !gatewayStatus || // Check if gatewayStatus is undefined
                              !gatewayStatus.InstanceStatuses?.[0]
                                ?.InstanceState?.Name || // Ensure InstanceState exists
                              gatewayStatus.InstanceStatuses[0].InstanceState
                                .Name !== "running" || // Ensure it's running
                              destination.srt_connection_limit === "1" // Check for connection limit
                            }
                            onClick={() => {
                              setThumbnailUrls([
                                {
                                  routeName: route.route_name,
                                  srtUrl: `srt://${gateway.host}:${destination.srt_port}?passphrase=${destination.srt_passphrase}`,
                                },
                              ]);
                              setDialogOpen(true);
                            }}
                          >
                            <ScreenshotMonitorIcon
                              sx={{
                                color: (theme) =>
                                  !gatewayStatus || // Check if gatewayStatus is undefined
                                  !gatewayStatus.InstanceStatuses?.[0]
                                    ?.InstanceState?.Name || // Ensure InstanceState exists
                                  gatewayStatus.InstanceStatuses[0]
                                    .InstanceState.Name !== "running" || // Ensure it's running
                                  destination.srt_connection_limit === "1" // Check for connection limit
                                    ? theme.palette.grey[500]
                                    : "white",
                              }}
                            />{" "}
                          </Button>
                        </span>
                      </Tooltip>
                    ) : null}
                    {/* Copy to Clipboard Button */}
                    <Tooltip title="Copy to clipboard" arrow>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          const srtUrl = `srt://${gateway.host}:${destination.srt_port}?passphrase=${destination.srt_passphrase}`;
                          navigator.clipboard.writeText(srtUrl);
                          setCopied(true); // Show copied state
                          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "white" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Open Grafana Stats (disabled)" arrow>
                      <Button variant="outlined">
                        <InsightsIcon sx={{ color: "white" }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              ),
              port: destination.srt_port,
              name: destination.srt_name,
            },
            position: { x: takerPosX, y: takerPosY },
            style: {
              backgroundColor: destination.srt_name.includes("monitor")
                ? theme.palette.secondary.light
                : theme.palette.primary.light,
              border: `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.contrastText,
              padding: 5,
              fontSize: "12px",
              minWidth: "450px",
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
          });

          // Store taker info for adjustment later
          takersByRoute[route.route_uid].push({
            id: takerNodeId,
            initialPosX: takerPosX,
            initialPosY: takerPosY,
          });

          // Connect this taker to its specific route
          parsedEdges.push({
            id: `edge-${routeNodeId}-${takerNodeId}`,
            source: routeNodeId,
            target: takerNodeId,
            animated: false,
            sourceHandle: Position.Right,
            targetHandle: Position.Left,
          });
        });
      });
    });

    // 2. Create mix nodes and connect them to the correct taker based on matching port_id and srt_port
    data.mix_configs.forEach((mix, mixIndex) => {
      const mixNodeId = `mix-${mixIndex}`;
      const sourceRouteId = `${mix.source_route_uid}`;
      const sourceRoute = sourceRoutes[mix.source_route_uid];
      const mixPortId = mix.port_id; // Retrieve the port_id from the mix config

      // Ensure the mix node is positioned to the right of its source route
      const mixPosX = sourceRoute.xPosition + mixOffsetX + 100;
      const mixPosY = sourceRoute.yPosition + mixIndex * nodeSpacingY;

      const label = `${mix.type} mix #${mixIndex + 1} ${mix.alias}`;

      // Add the mix node
      let mixNode = {
        id: mixNodeId,
        data: { label },
        position: { x: mixPosX, y: mixPosY },
        style: {
          backgroundColor: theme.palette.success.light, // Bright green background
          border: `1px solid ${theme.palette.success.dark}`, // Darker green border
          color: theme.palette.success.contrastText, // High contrast text color for readability
          padding: 5,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      };
      parsedNodes.push(mixNode);

      // Find the taker with a matching srt_port for this mix's port_id within this route's takers
      const matchingTaker = takersByRoute[sourceRouteId]?.find((taker) => {
        const takerNode = parsedNodes.find((node) => node.id === taker.id);
        const takerPort = takerNode?.data.port.split("_").pop();
        return takerPort === mixPortId;
      });

      if (matchingTaker) {
        // Create an edge between the matching taker node and the mix node
        if (sourceRoute.index > 0) {
          //meaning it is not the root source route
          mixesToUpdate.push({
            mixNode: mixNode,
            mix_item: mix,
            mix_index: mixIndex,
          });

          console.log(mixesToUpdate);
        }
        parsedEdges.push({
          id: `edge-${matchingTaker.id}-${mixNodeId}`,
          source: matchingTaker.id,
          target: mixNodeId,
          animated: false,
          sourceHandle: Position.Right,
          targetHandle: Position.Left,
        });
      }
    });

    // 3. Create and position destination routes to the right of their mix components
    let overall_offset = 0;
    data.mix_configs.forEach((mix, mixIndex) => {
      const destinationRouteId = `${mix.route_uid}`;
      const mixNode = parsedNodes.find((node) => node.id === `mix-${mixIndex}`);

      if (!mixNode) return; // Ensure mixNode exists

      // Set destination position relative to the mix node
      const destinationPosX = mixNode.position.x + destinationOffsetX;
      const takersToMove = takersByRoute[destinationRouteId] || []; // Get the takers for the destination route

      // Calculate the initial Y-position for the destination node based on the mix node and the number of takers
      let additional_offset = 0;
      if (mixIndex > 0) {
        let prev_mix_item = data.mix_configs[mixIndex - 1];
        const prevDestinationRouteId = `${prev_mix_item.route_uid}`;
        const prevTakers = takersByRoute[prevDestinationRouteId];
        const prevTakersLength = prevTakers.length;
        additional_offset = prevTakersLength + overall_offset;
      }

      const destinationPosY = mixNode.position.y + additional_offset;
      overall_offset = takersToMove.length * takerSpacingY;
      let destinationNode = parsedNodes.find(
        (node) => node.id === destinationRouteId
      );

      destinationNode.position = { x: destinationPosX, y: destinationPosY };

      // Create an edge from the mix node to the destination route node
      parsedEdges.push({
        id: `edge-mix-${mixIndex}-destination-${destinationRouteId}`,
        source: mixNode.id,
        target: destinationRouteId,
        animated: false,
        sourceHandle: Position.Right,
        targetHandle: Position.Left,
      });

      // Adjust the position of takers for the specific destination route
      takersToMove.forEach((taker, index) => {
        const takerNode = parsedNodes.find((node) => node.id === taker.id);
        if (takerNode) {
          // Set each taker's position below the destination node with appropriate Y offset
          takerNode.position.x = destinationPosX + takerOffsetX;
          takerNode.position.y = destinationPosY + index * takerSpacingY;
        }
      });
    });

    // 3. Add record nodes from record_configs and connect them based on destination name match
    data.record_configs.forEach((recordConfig, recordIndex) => {
      const sourceRouteId = recordConfig.source_route_uid;
      const sourceRoute = sourceRoutes[sourceRouteId];

      if (!sourceRoute) return;

      // Set up default record node position to the right of mix nodes
      const recordPosX = sourceRoute.xPosition + mixOffsetX + 750;
      const recordPosY = sourceRoute.yPosition + recordIndex * nodeSpacingY;

      const label = `${recordConfig.type} record #${recordIndex + 1} ${
        recordConfig.alias
      }`;

      // Add the record node
      parsedNodes.push({
        id: `record-${recordIndex}`,
        data: { label },
        position: { x: recordPosX, y: recordPosY },
        style: {
          backgroundColor: theme.palette.warning.light,
          border: `1px solid ${theme.palette.warning.main}`,
          color: theme.palette.warning.contrastText,
          padding: 5,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      });

      // Get the record type from recordConfig (e.g., "nablet")
      const recordType = recordConfig.type.toLowerCase();

      // Find takers by matching destination name with record type and index
      const matchingTaker = takersByRoute[sourceRouteId]?.find((taker) => {
        const takerNode = parsedNodes.find((node) => node.id === taker.id);
        return (
          takerNode?.data.name.toLowerCase().includes(recordType) &&
          takerNode?.data.name.includes(`_${recordIndex}`)
        );
      });

      if (matchingTaker) {
        // Adjust record node position to be to the right of its matching taker
        const takerNode = parsedNodes.find(
          (node) => node.id === matchingTaker.id
        );

        if (takerNode) {
          const adjustedRecordPosX =
            takerNode.position.x + takerOffsetX + recOffsetX;
          parsedNodes[parsedNodes.length - 1].position.x = adjustedRecordPosX; // Update record node position
          parsedNodes[parsedNodes.length - 1].position.y = takerNode.position.y; // Align vertically with taker
        }

        // Create a yellow edge between the matching taker node and the record node
        parsedEdges.push({
          id: `edge-${matchingTaker.id}-record-${recordIndex}`,
          source: matchingTaker.id,
          target: `record-${recordIndex}`,
          animated: false,
          sourceHandle: Position.Right,
          targetHandle: Position.Left,
        });
      }
    });

    setNodes(parsedNodes);
    setEdges(parsedEdges);
  };

  useEffect(() => {
    if (config && config.exchange_configs && config.mix_configs) {
      handleFitView();
      parseWorkflowData(config);
    } else {
      console.warn("Config data is missing or incomplete");
    }

    // Cleanup to reset nodes and edges when component is unmounted
    return () => {
      setFitViewTriggered(false); // Reset fitView trigger
      setNodes([]);
      setEdges([]);
    };
  }, [config, theme]);

  const handleFitView = () => {
    if (reactFlowInstance.current && !fitViewTriggered) {
      reactFlowInstance.current.fitView();
      setFitViewTriggered(true);
    } else {
      setTimeout(() => {
        handleFitView();
      }, 50);
    }
  };

  return (
    <>
      {state.database && state.status ? (
        <>
          <ThumbnailsDialog
            srtUrls={thumbnailUrls || []} // Pass multiple URLs
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            ident={gateway["host"] + " - " + gateway["path"]}
          />
          <Gateway gateway={gateway} index={gateway_index}></Gateway>
          <Button
            disabled={
              !gatewayStatus || // Check if gatewayStatus is undefined
              !gatewayStatus.InstanceStatuses?.[0]?.InstanceState?.Name || // Ensure InstanceState exists
              gatewayStatus.InstanceStatuses[0].InstanceState.Name !== "running"
            }
            style={{
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
              marginLeft: "30px",
              minWidth: "150px",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              const monitorStreams = [];

              // Loop through all routes in the configuration
              config.exchange_configs.forEach((exchange) => {
                exchange.api_config?.routes.forEach((route) => {
                  route.destinations.forEach((destination) => {
                    if (
                      destination.srt_name.toLowerCase().includes("monitor")
                    ) {
                      monitorStreams.push({
                        routeName:
                          route.route_name + " - " + destination.srt_name,
                        srtUrl: `srt://${gateway.host}:${destination.srt_port}?passphrase=${destination.srt_passphrase}`,
                      });
                    }
                  });
                });
              });

              if (monitorStreams.length > 0) {
                setThumbnailUrls(monitorStreams); // Set collected streams
                setDialogOpen(true); // Open dialog
              }
            }}
          >
            Open All Monitor Streams
          </Button>
          <Box display="flex" justifyContent={"center"}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              style={{ width: "100%", height: "100vh" }}
              onInit={(instance) => (reactFlowInstance.current = instance)}
              onNodesChange={handleFitView}
              onEdgesChange={handleFitView}
            >
              <Controls />
              <Background color="#aaaa" gap={16} />
            </ReactFlow>
          </Box>{" "}
        </>
      ) : null}
    </>
  );
};

export default Flow;
