import React from "react";
import { Grid, Typography, Link, Paper, Box } from "@mui/material";
import DnsIcon from "@mui/icons-material/Dns";
const GatewayDetails = ({ gateway }) => {
  return (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={12} sm={6} md={4}>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <DnsIcon color={"disabled"} />
          <Link href={`https://${gateway["host"]}`} underline="hover">
            {gateway["host"]}
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Device ID: {gateway["device_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>AWS Instance ID: {gateway["aws_instance_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {gateway["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {gateway["internal_ip"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default GatewayDetails;
