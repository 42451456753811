import React, { useState, useCallback } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import constants from "../../../../context/constants.json";
import SRTOutputCreator from "./SRTOutputCreator";

const OutputGroupCreator = ({ onCreate, ingestAudioChannels }) => {
  // For now, the only protocol is "srt"
  const [protocol, setProtocol] = useState(
    constants.mix.media_live.protocols[0]
  );
  const [outputs, setOutputs] = useState([]);

  // List of created output groups.
  const [createdOutputGroups, setCreatedOutputGroups] = useState([]);

  // Callback for adding a new output to the group.
  const handleAddOutput = useCallback((output) => {
    setOutputs((prev) => {
      const newOutput = {
        ...output,
        outputName: output.outputName + (prev.length + 1).toString(),
      };
      return [...prev, newOutput];
    });
  }, []);

  // Allow removal of an output from the current group.
  const handleRemoveOutput = useCallback((index) => {
    setOutputs((prev) => prev.filter((_, i) => i !== index));
  }, []);

  // Remove a created output group.
  const handleRemoveCreatedGroup = useCallback((index) => {
    setCreatedOutputGroups((prev) => prev.filter((_, i) => i !== index));
  }, []);

  // Finalize and add the current output group to the list of created groups.
  const handleSubmit = useCallback(() => {
    // Prevent creating an empty output group.
    if (outputs.length === 0) {
      alert("You must add at least one output before creating a group.");
      return;
    }
    const outputGroup = {
      protocol,
      outputs,
    };

    // Update the list of created output groups and log the entire config.
    setCreatedOutputGroups((prev) => {
      const updatedGroups = [...prev, outputGroup];
      console.log("Entire Config:", updatedGroups);
      return updatedGroups;
    });

    // If an onCreate callback is provided, call it with the new output group.
    if (onCreate) {
      onCreate(outputGroup);
    } else {
      console.log("Created Output Group:", outputGroup);
    }

    // Reset the form for the next output group.
    setProtocol(constants.mix.media_live.protocols[0]);
    setOutputs([]);
  }, [protocol, outputs, onCreate]);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">Create Output Group</Typography>
      {/* <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="protocol-select-label">Protocol</InputLabel>
        <Select
          labelId="protocol-select-label"
          value={protocol}
          label="Protocol"
          onChange={(e) => setProtocol(e.target.value)}
        >
          {constants.mix.media_live.protocols.map((p) => (
            <MenuItem key={p} value={p}>
              {p.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      {/* Logo URL field removed */}

      {/* Render SRT output creator (each output handles its own audio remix settings) */}
      {protocol === "srt" && (
        <SRTOutputCreator
          onAddOutput={handleAddOutput}
          ingestAudioChannels={ingestAudioChannels}
        />
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Current Outputs in Group</Typography>
        {outputs.length === 0 ? (
          <Typography>No outputs added yet.</Typography>
        ) : (
          <Stack direction="row" sx={{ mb: 2, flexWrap: "wrap", gap: 1 }}>
            {outputs.map((output, index) => {
              // Build a display string for the audio encodes.
              const audioDisplay = output.audioEncodes
                .map(
                  (enc) =>
                    `${enc.name} (${enc.profile.name})${
                      enc.remixSettings ? " (Remixed)" : ""
                    }`
                )
                .join(", ");
              return (
                <Chip
                  key={index}
                  label={`${output.outputName} [${output.integrationType}] - Video: ${output.videoEncodingProfile.name}, Audio: ${audioDisplay}`}
                  onDelete={() => handleRemoveOutput(index)}
                />
              );
            })}
          </Stack>
        )}
      </Box>

      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={handleSubmit}
        disabled={outputs.length === 0} // Disable if group is empty
      >
        Create Output Group
      </Button>

      {/* Display the list of created output groups */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Created Output Groups</Typography>
        {createdOutputGroups.length === 0 ? (
          <Typography>No output groups created yet.</Typography>
        ) : (
          <Stack direction="column" spacing={2}>
            {createdOutputGroups.map((group, index) => {
              // Build a summary for the group.
              const summary = `Protocol: ${group.protocol}, Outputs: ${group.outputs.length}`;
              return (
                <Chip
                  key={index}
                  label={summary}
                  onDelete={() => handleRemoveCreatedGroup(index)}
                />
              );
            })}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default OutputGroupCreator;
