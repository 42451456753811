import React, { useState, useContext, useEffect } from "react";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Promex from "./Promex";

export default function Promexs() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleStart = async (promexs) => {
    try {
      await requestConfirm("Are you sure you?");
      let url = process.env.REACT_APP_START_PROMEXS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promexs: promexs,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStop = async (promexs) => {
    try {
      await requestConfirm("Are you sure?");
      let url = process.env.REACT_APP_STOP_PROMEXS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promexs: promexs,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      {ConfirmDialog}
      {/* <p>{JSON.stringify(state.database.promexs)}</p> */}
      {state.database.promexs ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleStart(state.database.promexs)} // Using AWS Instance ID for action
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "0px",
              marginLeft: "10px",
            }}
          >
            Start All
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleStop(state.database.promexs)} // Using AWS Instance ID for action
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
          >
            Stop All
          </Button>
        </Box>
      ) : null}

      {state.database.promexs
        ?.sort((a, b) => {
          const regionComparison = a.region.localeCompare(b.region);

          // If the region is the same, compare by name (with numeric sorting)
          if (regionComparison === 0) {
            // Function to extract numeric value from the name
            const extractNumber = (name) => {
              const match = name.match(/(\d+)/); // Extract number from the string
              return match ? parseInt(match[0], 10) : 0; // Convert the matched number to an integer, or return 0 if no number found
            };

            const aNumber = extractNumber(a.name);
            const bNumber = extractNumber(b.name);

            // Compare the numeric values in the name
            if (aNumber === bNumber) {
              return a.name.localeCompare(b.name); // If numbers are equal, fall back to string comparison
            }
            return aNumber - bNumber; // Compare based on numeric values
          }

          return regionComparison; // If regions are not the same, compare regions
        })
        .map((promex, index) => {
          return <Promex promex={promex} key={index} index={index}></Promex>;
        })}
    </div>
  );
}
