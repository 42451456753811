import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import JSONDialog from "../Utility/JSONDialog";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import MixElixirDetails from "./Mix/MixElixirDetails";
import MixTvuDetails from "./Mix/MixTvuDetails";
import MixSpalkDetails from "./Mix/MixSpalkDetails";
import MixTvuMediaHubDetails from "./Mix/TvuMediaHubDetails";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Nirvana from "../Utility/Nirvana";
import MixMediaLiveDetails from "./Mix/MixMediaLiveDetails";
import CropIcon from "@mui/icons-material/Crop";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

function MonitorConfigs({ config, eventUid }) {
  const getLinkForType = (type) => {
    switch (type) {
      default:
        return "#"; // Default link in case of unknown type
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      {config.length > 0 ? (
        config.map((monitorConfig, exchangceIndex) => (
          <React.Fragment key={exchangceIndex}>
            <Paper
              key={exchangceIndex}
              sx={{ m: 2, p: 2, flex: "0 1 auto", flexGrow: 1 }}
            >
              <Box display="flex" flexDirection={"column"}>
                <Box
                  display="flex"
                  gap="10px"
                  alignItems={"center"}
                  style={{ borderBottom: "1px solid grey" }}
                >
                  {" "}
                  <Tooltip title={`Open ${monitorConfig.type}`}>
                    <IconButton
                      component="a"
                      href={getLinkForType(monitorConfig.type)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Open ${monitorConfig.type} link`}
                    >
                      <MonitorHeartIcon color="warning" />{" "}
                    </IconButton>
                  </Tooltip>
                  <Typography variant="h6">
                    {monitorConfig.type.toUpperCase()}
                  </Typography>
                  <Box
                    style={{
                      marginLeft: "auto",
                      paddingBottom: "5px",
                    }}
                  >
                    <JSONDialog
                      input_json={monitorConfig.profile}
                      name={monitorConfig.profile.alias}
                    ></JSONDialog>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </React.Fragment>
        ))
      ) : (
        <></>
      )}
    </Box>
  );
}

export default MonitorConfigs;
