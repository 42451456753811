import { useEffect, useRef } from "react";
import { useApiRequest } from "./useAPIRequest";

export const useAutoLambdaWarming = (
  urls = [],
  intervalMs = 5 * 60 * 1000,
  enabled = true
) => {
  const { warmupLambdas } = useApiRequest();
  const intervalRef = useRef(null);

  const startWarming = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        warmupLambdas(urls);
      }, intervalMs);
    }
  };

  const stopWarming = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const shouldPause = () =>
    document.visibilityState === "hidden" || !document.hasFocus();

  useEffect(() => {
    if (!enabled || urls.length === 0) return;

    // Initial warmup
    warmupLambdas(urls);
    if (!shouldPause()) startWarming();

    const handleStateChange = () => {
      if (shouldPause()) {
        stopWarming();
      } else {
        warmupLambdas(urls); // Optional: immediate warmup on resume
        startWarming();
      }
    };

    document.addEventListener("visibilitychange", handleStateChange);
    window.addEventListener("blur", handleStateChange);
    window.addEventListener("focus", handleStateChange);

    return () => {
      stopWarming();
      document.removeEventListener("visibilitychange", handleStateChange);
      window.removeEventListener("blur", handleStateChange);
      window.removeEventListener("focus", handleStateChange);
    };
  }, [warmupLambdas, urls, intervalMs, enabled]);
};
