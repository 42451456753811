import React, { useState, useContext, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Alert,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../../context/Context";
import JsonImporter from "../../../Utility/JsonImporter";
import MediaLiveAudioMixer from "./MediaLiveAudioMixer";
import constants from "../../../../context/constants.json";
import OutputGroupCreator from "./OutputGroupCreator";

// Helper to create new form data
const getInitialFormData = () => {
  const initialInputSpec = constants.mix.media_live.input_specs_profiles[0];
  return {
    uid: uuidv4(),
    alias: "",
    region: constants.mix.media_live.regions[0],
    rundown: [],
    input_specs: initialInputSpec,
    graphics: [],
    output_groups: [],
    ingest_audio_channels: 6,
    distributed_inputs: false,
  };
};

const AddMedialiveProfileForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);
  const { database } = state;
  const [formData, setFormData] = useState(getInitialFormData());
  const [selectedGraphicsProfile, setSelectedGraphicsProfile] = useState(null);
  const [selectedAbility, setSelectedAbility] = useState(null);
  const [error, setError] = useState("");

  // Callback to handle a newly created output group.
  const handleOutputGroupCreate = useCallback((newOutputGroup) => {
    setFormData((prev) => ({
      ...prev,
      output_groups: [...prev.output_groups, newOutputGroup],
    }));
  }, []);

  // Update form fields (for text fields, etc.)
  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  // Handle JSON import
  const handleJsonImport = useCallback((importedJson) => {
    setFormData((prev) => ({ ...prev, ...importedJson }));
  }, []);

  // Add a new rundown task
  const handleAddRundownTask = useCallback((taskToAdd) => {
    setFormData((prev) => ({
      ...prev,
      rundown: [...prev.rundown, taskToAdd],
    }));
  }, []);

  // Remove a rundown task by index
  const handleRemoveRundownTask = useCallback((index) => {
    setFormData((prev) => ({
      ...prev,
      rundown: prev.rundown.filter((_, i) => i !== index),
    }));
  }, []);

  const handleAddGraphicsProfile = (graphics_profile) => {
    setSelectedGraphicsProfile(graphics_profile);
    setFormData({
      ...formData,
      graphics:
        Array.isArray(graphics_profile) && graphics_profile.length === 0
          ? [] // If None is selected, keep graphics as an empty array.
          : [graphics_profile],
    });
  };

  const handleCheckboxChange = useCallback((event) => {
    setFormData((prev) => ({
      ...prev,
      distributed_inputs: event.target.checked,
    }));
  }, []);

  // Reset the form (and generate a new uid) when closing
  const handleDialogClose = useCallback(() => {
    setFormData(getInitialFormData());
    onClose();
  }, [onClose]);

  // Validate required fields and submit
  const handleSubmit = useCallback(() => {
    // Ensure at least one output group exists.
    if (formData.output_groups.length === 0) {
      setError("At least one output group is required.");
      return;
    }

    // Validate form fields per schema.
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && !formData[key]) {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });
    if (!isValid) return;

    console.log("Entire Config:", JSON.stringify(formData, null, 2));

    if (onAdd) {
      onAdd(formData);
    }

    onClose();
    setError("");
    setFormData(getInitialFormData());
  }, [formData, schema, onAdd, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-between",
            borderBottom: "1px solid grey",
            p: "15px",
          }}
        >
          <Typography variant="body1">Add New Medialive Profile</Typography>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          margin="normal"
          name="uid"
          label="Uid"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.distributed_inputs}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label='Distributed Inputs (Yes - uses "main" and "backup", No uses "main" only)'
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="region-select-label">Region</InputLabel>
          <Select
            labelId="region-select-label"
            name="region"
            value={formData.region}
            onChange={handleChange}
            label="Region"
          >
            {constants.mix.media_live.regions?.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel id="input_specs-select-label">Input Specs</InputLabel>
          <Select
            labelId="input_specs-select-label"
            name="input_specs"
            value={formData.input_specs}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, input_specs: e.target.value }))
            }
            label="Input Specs"
            renderValue={(selected) => {
              return selected
                ? `${selected.Codec || selected.codec} - ${
                    selected.MaximumBitrate || selected.maximumBitrate
                  } - ${selected.Resolution || selected.resolution}`
                : "";
            }}
          >
            {constants.mix.media_live.input_specs_profiles.map(
              (spec, index) => {
                const specDisplay = `${spec.Codec || spec.codec} - ${
                  spec.MaximumBitrate || spec.maximumBitrate
                } - ${spec.Resolution || spec.resolution}`;
                return (
                  <MenuItem key={index} value={spec}>
                    {specDisplay}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          name="ingest_audio_channels"
          label="Number of Ingest Audio Channels"
          type="number"
          fullWidth
          variant="outlined"
          InputProps={{ inputProps: { min: 2, max: 8 } }}
          value={formData.ingest_audio_channels.toString()}
          onChange={(e) =>
            setFormData((prev) => {
              let value =
                e.target.value === "" ? "" : parseInt(e.target.value, 10);
              if (value !== "" && value < 2) value = 2;
              if (value !== "" && value > 8) value = 8;
              return { ...prev, ingest_audio_channels: value };
            })
          }
        />

        {/* Graphics Profile Section */}
        <FormControl fullWidth margin="dense">
          <InputLabel id="graphics-profile-select-label">
            Graphics Profile
          </InputLabel>
          <Select
            labelId="graphics-profile-select-label"
            value={selectedGraphicsProfile || ""}
            onChange={(e) => handleAddGraphicsProfile(e.target.value)}
            label="Graphics Profile"
          >
            <MenuItem key={"default_value"} value={[]}>
              None
            </MenuItem>
            {database.graphics_profiles.map((profile) => (
              <MenuItem key={profile.uid} value={profile}>
                {profile.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Output Groups and Medialive Audio Mixer */}
        <OutputGroupCreator
          onCreate={handleOutputGroupCreate}
          ingestAudioChannels={formData.ingest_audio_channels}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="medialive-abilities-select-label">
            Medialive Rundown Tasks
          </InputLabel>
          <Select
            labelId="medialive-abilities-select-label"
            value={selectedAbility || ""}
            onChange={(e) => setSelectedAbility(e.target.value)}
            label="Medialive Rundown Tasks"
          >
            {database.medialive_abilities.map((ability) => (
              <MenuItem key={ability.uid} value={ability}>
                {ability.alias}
              </MenuItem>
            ))}
          </Select>
          {selectedAbility && (
            <TextField
              autoFocus
              margin="normal"
              name="execution_offset"
              label="Execution Offset"
              type="text"
              fullWidth
              variant="outlined"
              value={selectedAbility.execution_offset || ""}
              onChange={(e) =>
                setSelectedAbility({
                  ...selectedAbility,
                  execution_offset: e.target.value,
                })
              }
            />
          )}
          <Button
            variant="contained"
            onClick={() => {
              if (selectedAbility) {
                handleAddRundownTask(selectedAbility);
              }
            }}
            sx={{ mt: 1 }}
            disabled={!selectedAbility}
          >
            Add Rundown Task
          </Button>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 3 }}>
            {formData.rundown.map((ability, index) => (
              <Chip
                key={`${ability.uid}-${index}`}
                label={`${ability.alias} : ${ability.execution_offset}`}
                onDelete={() => handleRemoveRundownTask(index)}
                color="primary"
              />
            ))}
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDialogClose}
          color="secondary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={formData.output_groups.length === 0} // Disabled if no output group configured.
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMedialiveProfileForm;
