import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import JSONDialog from "../Utility/JSONDialog";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import MixElixirDetails from "./Mix/MixElixirDetails";
import MixTvuDetails from "./Mix/MixTvuDetails";
import MixSpalkDetails from "./Mix/MixSpalkDetails";
import MixTvuMediaHubDetails from "./Mix/TvuMediaHubDetails";

import Nirvana from "../Utility/Nirvana";
import MixMediaLiveDetails from "./Mix/MixMediaLiveDetails";
function MixConfigs({ config, eventUid }) {
  const getLinkForType = (type) => {
    switch (type) {
      case "tvu_mediahub":
        return "https://mediahub.tvunetworks.com/mediahub";
      case "tvu":
        return "https://commentator.tvunetworks.com/commentator/event";
      case "spalk":
        return "https://app.spalk.tv/dashboard";
      case "elixir":
        return "https://us-east-1.console.aws.amazon.com/ecs/v2/clusters/elixir-cluster/services?region=us-east-1";
      case "medialive":
        return "https://us-east-1.console.aws.amazon.com/medialive/home?region=us-east-1#/";
      default:
        return "#"; // Default link in case of unknown type
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      {config.length > 0 ? (
        config.map((mixConfig, mixIndex) => (
          <Paper
            key={mixIndex}
            sx={{ m: 2, p: 2, flex: "0 1 auto", flexGrow: 1 }}
          >
            <Box display="flex" flexDirection={"column"}>
              <Box
                display="flex"
                gap="10px"
                alignItems={"center"}
                style={{ borderBottom: "1px solid grey" }}
              >
                {" "}
                <Tooltip title={`Open ${mixConfig.type}`}>
                  <IconButton
                    component="a"
                    href={getLinkForType(mixConfig.type)}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Open ${mixConfig.type} link`}
                  >
                    <VideoSettingsIcon
                      style={{
                        color:
                          mixConfig.type === "tvu"
                            ? "green"
                            : mixConfig.type === "elixir"
                            ? "purple"
                            : mixConfig.type === "spalk"
                            ? "orange"
                            : mixConfig.type === "medialive"
                            ? "#db6a14"
                            : mixConfig.type === "tvu_mediahub"
                            ? "#19d104"
                            : "black",
                      }}
                    />{" "}
                  </IconButton>
                </Tooltip>
                <Typography variant="h6">
                  {mixConfig.type.toUpperCase()}
                </Typography>
                <Box
                  style={{
                    marginLeft: "auto",
                    paddingBottom: "5px",
                  }}
                >
                  <JSONDialog
                    input_json={mixConfig.profile}
                    name={mixConfig.profile.alias}
                  ></JSONDialog>
                </Box>
              </Box>

              {mixConfig.type === "elixir" && (
                <MixElixirDetails
                  mixConfig={mixConfig}
                  mixIndex={mixIndex}
                  eventUid={eventUid}
                />
              )}
              {mixConfig.type === "tvu" && (
                <MixTvuDetails
                  mixConfig={mixConfig}
                  mixIndex={mixIndex}
                  eventUid={eventUid}
                />
              )}
              {mixConfig.type === "spalk" && (
                <MixSpalkDetails
                  mixConfig={mixConfig}
                  mixIndex={mixIndex}
                  eventUid={eventUid}
                />
              )}
              {mixConfig.type === "tvu_mediahub" && (
                <MixTvuMediaHubDetails
                  mixConfig={mixConfig}
                  mixIndex={mixIndex}
                  eventUid={eventUid}
                />
              )}
              {mixConfig.type === "medialive" && (
                <MixMediaLiveDetails
                  mixConfig={mixConfig}
                  mixIndex={mixIndex}
                  eventUid={eventUid}
                />
              )}
            </Box>
          </Paper>
        ))
      ) : (
        <></>
      )}
    </Box>
  );
}

export default MixConfigs;
