import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Slider,
  Button,
  TextField,
  List,
  ListItem,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const MediaLiveAudioMixer = ({ onRemixChange, ingestAudioChannels }) => {
  const [outputChannels, setOutputChannels] = useState([]);
  const [selectedInputs, setSelectedInputs] = useState([]);
  const [gainValues, setGainValues] = useState({});
  // Store output channel value in 0-based format.
  const [manualOutputChannel, setManualOutputChannel] = useState(0);

  const availableChannels = Array.from(
    { length: ingestAudioChannels },
    (_, i) => ({
      label: `Channel ${i + 1}`,
      value: i,
    })
  );

  // Updated getNextAvailableOutputChannel to use 0-based numbering.
  const getNextAvailableOutputChannel = (existingNumbers) => {
    for (let i = 0; i <= existingNumbers.length; i++) {
      if (!existingNumbers.includes(i)) return i;
    }
  };

  // Update selectedInputs when outputChannels change.
  useEffect(() => {
    const updatedInputs =
      outputChannels
        .find((output) => output.OutputChannel === manualOutputChannel)
        ?.InputChannelLevels.map((ch) => ({
          label: `Channel ${ch.InputChannel + 1}`,
          value: ch.InputChannel,
        })) || [];
    setSelectedInputs(updatedInputs);
  }, [outputChannels, manualOutputChannel]);

  // Notify parent of changes to remix settings.
  useEffect(() => {
    if (onRemixChange) {
      onRemixChange({ ChannelMappings: outputChannels });
    }
  }, [outputChannels, onRemixChange]);

  // Add or update an output channel.
  const handleAddOutputChannel = () => {
    // If already at 2 channels, do not add any more.
    if (outputChannels.length >= 2) {
      return;
    }
    if (selectedInputs.length === 0) return;

    // Use manualOutputChannel directly (0-based)
    const outputChannelNumber = manualOutputChannel;
    const newOutputChannel = {
      InputChannelLevels: selectedInputs.map((input) => ({
        InputChannel: input.value,
        Gain: gainValues[input.value] || 0,
      })),
      OutputChannel: outputChannelNumber,
    };

    setOutputChannels((prev) => {
      const filteredChannels = prev.filter(
        (ch) => ch.OutputChannel !== outputChannelNumber
      );
      const updatedChannels = [...filteredChannels, newOutputChannel];
      const sortedChannels = updatedChannels.sort(
        (a, b) => a.OutputChannel - b.OutputChannel
      );

      // Update manualOutputChannel using 0-based numbering.
      if (sortedChannels.length < 2) {
        setManualOutputChannel(
          getNextAvailableOutputChannel(
            sortedChannels.map((ch) => ch.OutputChannel)
          )
        );
      }

      return sortedChannels;
    });
  };

  // Remove an output channel.
  const handleRemoveOutputChannel = (outputChannelNumber) => {
    setOutputChannels((prev) => {
      const filtered = prev.filter(
        (channel) => channel.OutputChannel !== outputChannelNumber
      );
      setManualOutputChannel(
        getNextAvailableOutputChannel(filtered.map((ch) => ch.OutputChannel))
      );
      return filtered;
    });
  };

  // Remove an individual input channel from an output.
  const handleRemoveInputChannel = (outputChannel, inputChannel) => {
    setOutputChannels((prev) =>
      prev.map((output) =>
        output.OutputChannel === outputChannel
          ? {
              ...output,
              InputChannelLevels: output.InputChannelLevels.filter(
                (ch) => ch.InputChannel !== inputChannel
              ),
            }
          : output
      )
    );

    // Remove gain value from state.
    setGainValues((prev) => {
      const updatedGains = { ...prev };
      delete updatedGains[inputChannel];
      return updatedGains;
    });

    // Update Autocomplete selections.
    setSelectedInputs((prev) =>
      prev.filter((input) => input.value !== inputChannel)
    );
  };

  // Handle gain change.
  const handleGainChange = (input, value) => {
    setGainValues((prev) => ({ ...prev, [input]: value }));
  };

  // Reset gain for a specific channel.
  const handleResetGain = (input) => {
    setGainValues((prev) => ({ ...prev, [input]: 0 }));
  };

  return (
    <Box
      sx={{
        minWidth: 500,
        p: 3,
        border: "1px solid #ddd",
        borderRadius: 1,
        my: 2,
      }}
    >
      {/* Input Channel Selection */}
      <Autocomplete
        multiple
        options={availableChannels}
        value={selectedInputs}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => setSelectedInputs(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Select Input Channels" />
        )}
      />

      {/* Gain Control Sliders with Delete Buttons */}
      {selectedInputs.map((input) => (
        <Box
          key={input.value}
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 600, minWidth: "70px" }}
          >
            {input.label}
          </Typography>
          <Slider
            value={gainValues[input.value] || 0}
            onChange={(e, value) => handleGainChange(input.value, value)}
            step={1}
            min={-60}
            max={6}
            marks={[
              { value: -60, label: "-60dB" },
              { value: -30, label: "-30" },
              { value: 0, label: "0" },
              { value: 6, label: "+6" },
            ]}
            sx={{ flexGrow: 1, height: 4 }}
          />
          <IconButton
            size="small"
            onClick={() => handleResetGain(input.value)}
            sx={{ ml: 1 }}
          >
            <RestartAltIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() =>
              handleRemoveInputChannel(manualOutputChannel, input.value)
            }
            sx={{ ml: 1 }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <Typography style={{ width: "40px" }}>
            {gainValues[input.value]}
          </Typography>
        </Box>
      ))}

      {/* Output Channel Number Selection */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 3 }}>
        <TextField
          label="Output Channel"
          type="number"
          // Display the output channel as 1-based.
          value={manualOutputChannel + 1}
          onChange={(e) => {
            const newValue = parseInt(e.target.value, 10);
            // Store as 0-based
            setManualOutputChannel(newValue - 1);
          }}
          inputProps={{ min: 1, style: { textAlign: "center", width: "50px" } }}
          sx={{ width: "120px" }}
        />
        <Button
          variant="contained"
          onClick={handleAddOutputChannel}
          disabled={selectedInputs.length === 0 || outputChannels.length >= 2}
        >
          Add Output Channel {manualOutputChannel + 1}
        </Button>
      </Box>

      {/* Output Channel List */}
      <List>
        {outputChannels.map((output) => (
          <ListItem
            key={output.OutputChannel}
            secondaryAction={
              <IconButton
                onClick={() => handleRemoveOutputChannel(output.OutputChannel)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <Typography variant="body2">
              Output Channel {output.OutputChannel + 1} Mix:{" "}
              {output.InputChannelLevels.map(
                (ch) =>
                  `Input Channel ${ch.InputChannel + 1} (Gain: ${ch.Gain} dB)`
              ).join(", ")}
            </Typography>
          </ListItem>
        ))}
      </List>

      {/* JSON Output Preview */}
      {/* <TextField
        label="Generated JSON"
        multiline
        fullWidth
        value={JSON.stringify({ ChannelMappings: outputChannels }, null, 2)}
        sx={{ mt: 3 }}
      /> */}
    </Box>
  );
};

export default MediaLiveAudioMixer;
