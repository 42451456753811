import React from "react";
import GatewayRoute from "./GatewayRoute";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Paper } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

export default function GatewayConfig({ configs, gateway, triggerReload }) {
  const theme = useTheme();
  return (
    <div>
      <Paper>
        {configs?.map((conf, index) =>
          conf.config.map((route, index) => (
            <Accordion
              key={index}
              style={{
                backgroundColor:
                  index % 2 !== 0
                    ? theme.palette.mode === "dark"
                      ? "#242424"
                      : ""
                    : theme.palette.mode === "dark"
                    ? "#1f1f21"
                    : "#f1f1f1",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                {/* You can choose to display a summary here, such as the route name */}
                <Typography>Route: {route.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <GatewayRoute
                  route={route}
                  host={conf.host}
                  index={index}
                  gateway={gateway}
                  triggerReload={triggerReload}
                />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Paper>
    </div>
  );
}
