import React, { useState, useCallback } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Chip,
  Stack,
} from "@mui/material";
import constants from "../../../../context/constants.json";
import MediaLiveAudioMixer from "./MediaLiveAudioMixer";

const SRTOutputCreator = ({ onAddOutput, ingestAudioChannels }) => {
  // Basic output configuration states.
  const [outputName, setOutputName] = useState("");
  const [integrationType, setIntegrationType] = useState("hbmc");
  const [outputLogoUrl, setOutputLogoUrl] = useState("");

  // Custom integration fields (only used when integrationType === "custom").
  const [mainHost, setMainHost] = useState("");
  const [mainPort, setMainPort] = useState("");
  const [backupHost, setBackupHost] = useState("");
  const [backupPort, setBackupPort] = useState("");

  const initialVideoProfile =
    constants.mix.media_live.video_encoding_profiles[0];
  const initialAudioEncode =
    constants.mix.media_live.audio_encoding_profiles[0];

  const [videoEncodingProfile, setVideoEncodingProfile] =
    useState(initialVideoProfile);

  // Manage a list of audio encode configurations per output.
  const [audioEncodes, setAudioEncodes] = useState([]);

  // States for the new audio encode configuration form.
  const [newAudioEncodeName, setNewAudioEncodeName] = useState("");
  const [newAudioEncode, setNewAudioEncode] = useState(initialAudioEncode);
  const [newEnableAudioMixing, setNewEnableAudioMixing] = useState(false);
  const [newAudioMixSettings, setNewAudioMixSettings] = useState(null);

  // Callback to capture remix settings from MediaLiveAudioMixer for this audio encode.
  const handleNewMixChange = useCallback((mixSettings) => {
    setNewAudioMixSettings(mixSettings);
  }, []);

  // Add the current new audio encode configuration to the list.
  const handleAddAudioEncode = useCallback(() => {
    const audioEncodeConfig = {
      name: newAudioEncodeName.trim(),
      profile: newAudioEncode,
      ...(newEnableAudioMixing && { remixSettings: newAudioMixSettings }),
    };
    setAudioEncodes((prev) => [...prev, audioEncodeConfig]);
    // Reset the new audio encode fields.
    setNewAudioEncode(initialAudioEncode);
    setNewAudioEncodeName("");
    setNewEnableAudioMixing(false);
    setNewAudioMixSettings(null);
  }, [
    newAudioEncode,
    newAudioEncodeName,
    newEnableAudioMixing,
    newAudioMixSettings,
    initialAudioEncode,
  ]);

  // Allow removal of a specific audio encode configuration.
  const handleRemoveAudioEncode = useCallback((index) => {
    setAudioEncodes((prev) => prev.filter((_, i) => i !== index));
  }, []);

  // When adding the final output, include the list of audio encode configurations.
  const handleAddOutput = useCallback(() => {
    const newOutput = {
      outputName: outputName.trim(),
      logoUrl: outputLogoUrl.trim(),
      integrationType,
      videoEncodingProfile,
      audioEncodes, // List of audio encode configurations
      ...(integrationType === "custom" && {
        custom_srt: {
          main_host: mainHost.trim(),
          main_port: mainPort.trim(),
          backup_host: backupHost.trim(),
          backup_port: backupPort.trim(),
        },
      }),
    };
    onAddOutput(newOutput);

    // Reset all fields for the next output.
    setOutputName("");
    setOutputLogoUrl("");
    setIntegrationType("hbmc");
    setVideoEncodingProfile(initialVideoProfile);
    setAudioEncodes([]);
    setNewAudioEncode(initialAudioEncode);
    setNewAudioEncodeName("");
    setNewEnableAudioMixing(false);
    setNewAudioMixSettings(null);
    // Reset custom integration fields.
    setMainHost("");
    setMainPort("");
    setBackupHost("");
    setBackupPort("");
  }, [
    outputName,
    outputLogoUrl,
    integrationType,
    videoEncodingProfile,
    audioEncodes,
    onAddOutput,
    initialVideoProfile,
    initialAudioEncode,
    mainHost,
    mainPort,
    backupHost,
    backupPort,
  ]);

  return (
    <Box pt={2}>
      <TextField
        label="SRT Output Name"
        value={outputName}
        onChange={(e) =>
          setOutputName(e.target.value.replace(/[^A-Za-z0-9]/g, ""))
        }
        fullWidth
        sx={{ mb: 2 }}
        required
        error={outputName.trim() === ""}
      />

      <TextField
        label="Logo URL (per output)"
        value={outputLogoUrl}
        onChange={(e) => setOutputLogoUrl(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
        helperText={
          "Logo should be same size as rendition e.g. 1920x1080, .png file, it will cover the entire screen (without alpha) and has to be accessible via URL, leave blank if no logo required."
        }
      />

      {/* Integration Type Selector */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="integration-type-label">Integration Type</InputLabel>
        <Select
          labelId="integration-type-label"
          value={integrationType}
          label="Integration Type"
          onChange={(e) => setIntegrationType(e.target.value)}
        >
          <MenuItem value="hbmc">HBMC</MenuItem>
          <MenuItem value="fco">FCO</MenuItem>
          {/* <MenuItem value="custom">Custom</MenuItem> */}
        </Select>
      </FormControl>
      {/* Render custom SRT fields if integrationType is "custom" */}
      {integrationType === "custom" && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Custom SRT Configuration
          </Typography>
          <TextField
            label="Main Host"
            value={mainHost}
            onChange={(e) => setMainHost(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Main Port"
            value={mainPort}
            onChange={(e) => setMainPort(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Backup Host"
            value={backupHost}
            onChange={(e) => setBackupHost(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Backup Port"
            value={backupPort}
            onChange={(e) => setBackupPort(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
      )}

      {/* Video Encoding Profile Selector */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="video-encoding-label">
          Video Encoding Profile
        </InputLabel>
        <Select
          labelId="video-encoding-label"
          value={videoEncodingProfile.name}
          label="Video Encoding Profile"
          onChange={(e) => {
            const selectedProfile =
              constants.mix.media_live.video_encoding_profiles.find(
                (profile) => profile.name === e.target.value
              );
            setVideoEncodingProfile(selectedProfile);
          }}
        >
          {constants.mix.media_live.video_encoding_profiles.map((profile) => (
            <MenuItem key={profile.name} value={profile.name}>
              {profile.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Section for Audio Encode Configurations */}
      <Typography variant="subtitle2" gutterBottom>
        Add Audio Encode Configuration
      </Typography>

      {/* Audio Encode Name Input */}
      <TextField
        label="Audio Encode Name"
        value={newAudioEncodeName}
        onChange={(e) =>
          setNewAudioEncodeName(e.target.value.replace(/[^A-Za-z0-9]/g, ""))
        }
        fullWidth
        sx={{ mb: 2 }}
        required
        error={newAudioEncodeName.trim() === ""}
      />

      {/* Audio Encode Selector */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="audio-encode-label">Audio Encode</InputLabel>
        <Select
          labelId="audio-encode-label"
          value={newAudioEncode.name}
          label="Audio Encode"
          onChange={(e) => {
            const selectedAudio =
              constants.mix.media_live.audio_encoding_profiles.find(
                (profile) => profile.name === e.target.value
              );
            setNewAudioEncode(selectedAudio);
          }}
        >
          {constants.mix.media_live.audio_encoding_profiles.map((profile) => (
            <MenuItem key={profile.name} value={profile.name}>
              {profile.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Toggle for enabling remix on this Audio Encode */}
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={newEnableAudioMixing}
              onChange={(e) => setNewEnableAudioMixing(e.target.checked)}
              color="primary"
              disabled={newAudioEncode.name == "Passthrough"}
            />
          }
          label="Enable Audio Remix"
        />

        {/* Render the Audio Remix Mixer if remixing is enabled */}
        {newEnableAudioMixing && (
          <>
            <MediaLiveAudioMixer
              onRemixChange={handleNewMixChange}
              ingestAudioChannels={ingestAudioChannels}
            />
          </>
        )}

        <Button
          variant="contained"
          onClick={handleAddAudioEncode}
          sx={{ m: 2 }}
          disabled={newAudioEncodeName.trim() === ""}
        >
          Add Audio Encode
        </Button>

        {/* List of added Audio Encode Configurations */}
        {audioEncodes.length > 0 && (
          <Stack direction="row" spacing={1} sx={{ mb: 2 }} flexWrap="wrap">
            {audioEncodes.map((encode, index) => (
              <Chip
                key={index}
                label={`${encode.name} (${encode.profile.name})${
                  encode.remixSettings ? " (Remixed)" : ""
                }`}
                onDelete={() => handleRemoveAudioEncode(index)}
              />
            ))}
          </Stack>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={handleAddOutput}
        style={{ marginTop: "20px" }}
        disabled={outputName.trim() === "" || audioEncodes.length === 0}
      >
        Add Output
      </Button>
    </Box>
  );
};

export default SRTOutputCreator;
