import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Card,
  Paper,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  IconButton,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Checkbox,
  TextField,
} from "@mui/material";
import ArchivedScheduleRow from "./ArchivedScheduleRow"; // Updated row component
import useCronTranslator from "../Utility/useCronTranslator";
import DataObjectIcon from "@mui/icons-material/DataObject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Description from "@mui/icons-material/Description";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Nirvana from "../Utility/Nirvana";
import moment from "moment-timezone";
import CustomDateTimePicker from "../Utility/CustomDateTimePicker";
import useUnixMsToDate from "../Utility/useUnixMsToDate";
import BoltIcon from "@mui/icons-material/Bolt";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";

function ArchivedScheduleTable({ schedules, timezone }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("execution_time");
  const translateCronExpression = useCronTranslator(timezone);
  const translateUnixExpression = useUnixMsToDate();

  // Filter state
  const [timeFilter, setTimeFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [taskFilter, setTaskFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  // Distinct dropdown values
  const [events, setEvents] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [types, setTypes] = useState([]);

  const [filteredSchedules, setFilteredSchedules] = useState(schedules);

  // Bulk selection and update state
  const [selected, setSelected] = useState([]);
  const [bulkNewTime, setBulkNewTime] = useState(null);
  const [bulkNewTimezone, setBulkNewTimezone] = useState(null);

  // Extract distinct dropdown values from schedules
  useEffect(() => {
    if (schedules && schedules.length > 0) {
      const eventSet = new Set();
      const numberSet = new Set();
      const taskSet = new Set();
      const typeSet = new Set();
      schedules.forEach((schedule) => {
        const [event, number, task, type] =
          schedule.schedule_description.split(";");
        eventSet.add(event);
        numberSet.add(number);
        taskSet.add(task);
        typeSet.add(type);
      });
      setEvents([...eventSet]);
      setNumbers([...numberSet]);
      setTasks([...taskSet]);
      setTypes([...typeSet]);
    }
  }, [schedules]);

  // Filter schedules based on filters
  const handleFilterChange = () => {
    const filteredData = schedules.filter((schedule) => {
      const [event, number, task, type] =
        schedule.schedule_description.split(";");
      const executionTime = moment
        .tz(Number(schedule.execution_time), timezone)
        .format("MM/DD/YYYY HH:mm");
      const matchesTime =
        timeFilter === "" || executionTime.includes(timeFilter);
      const matchesEvent = eventFilter
        ? event.toLowerCase() === eventFilter.toLowerCase()
        : true;
      const matchesNumber = numberFilter
        ? number.toLowerCase() === numberFilter.toLowerCase()
        : true;
      const matchesTask = taskFilter
        ? task.toLowerCase() === taskFilter.toLowerCase()
        : true;
      const matchesType = typeFilter
        ? type.toLowerCase() === typeFilter.toLowerCase()
        : true;
      return (
        matchesTime &&
        matchesEvent &&
        matchesNumber &&
        matchesTask &&
        matchesType
      );
    });
    setFilteredSchedules(filteredData);
    setPage(0);
  };

  useEffect(() => {
    handleFilterChange();
  }, [
    schedules,
    timeFilter,
    eventFilter,
    numberFilter,
    taskFilter,
    typeFilter,
  ]);

  // Reset bulk selection on filter change
  useEffect(() => {
    setSelected([]);
  }, [timeFilter, eventFilter, numberFilter, taskFilter, typeFilter]);

  // Sorting function
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = filteredSchedules
    ? [...filteredSchedules].sort((a, b) => {
        let valueA, valueB;
        if (orderBy === "execution_time") {
          valueA = moment
            .tz(Number(a.execution_time), timezone)
            .format("MM/DD/YYYY HH:mm");
          valueB = moment
            .tz(Number(b.execution_time), timezone)
            .format("MM/DD/YYYY HH:mm");
        } else {
          valueA = a[orderBy];
          valueB = b[orderBy];
        }
        if (valueA < valueB) return orderDirection === "asc" ? -1 : 1;
        if (valueA > valueB) return orderDirection === "asc" ? 1 : -1;
        return 0;
      })
    : [];

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleBulkRun = async () => {
    if (selected.length === 0) return;
    try {
      await requestConfirm(
        "Are you sure you want to re-run the selected archived schedules (set to run in 2 minutes)?"
      );
      // Calculate new execution time as a Unix timestamp (ms)
      const runTime = moment().add(2, "minutes").valueOf();
      const url =
        process.env.REACT_APP_TASKS_URL + "/events/recreate_schedules";
      const selectedSchedules = sortedData.filter((schedule) =>
        selected.includes(schedule.uid)
      );
      // Build an array of schedule update objects
      const scheduleUpdates = selectedSchedules.map((schedule) => ({
        schedule_name: schedule.schedule_name,
        schedule_description: schedule.schedule_description,
        input: schedule.input,
        function_name: schedule.function_name,
        execution_time: runTime,
      }));
      // Build payload with the list of schedules
      const payload = { input: { schedules: scheduleUpdates } };
      await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        },
        "schedules_archive",
        onSuccessfulModification
      );
      setSelected([]);
    } catch (error) {
      console.log("Bulk run action cancelled", error);
    }
  };

  const handleBulkUpdateTime = async () => {
    if (selected.length === 0) return;
    if (!bulkNewTime) {
      alert("Please select a new time for bulk update.");
      return;
    }
    try {
      await requestConfirm(
        "Are you sure you want to update the time for the selected archived schedules?"
      );
      const tz = bulkNewTimezone || timezone;
      // Convert the selected new time to a Unix timestamp (ms) using the given timezone
      const newExecutionTime = moment.tz(bulkNewTime, tz).valueOf();
      const url =
        process.env.REACT_APP_TASKS_URL + "/events/recreate_schedules";
      const selectedSchedules = sortedData.filter((schedule) =>
        selected.includes(schedule.uid)
      );
      // Build an array of schedule update objects with the new execution time
      const scheduleUpdates = selectedSchedules.map((schedule) => ({
        schedule_name: schedule.schedule_name,
        schedule_description: schedule.schedule_description,
        input: schedule.input,
        function_name: schedule.function_name,
        execution_time: newExecutionTime,
      }));
      // Build payload with the list of schedules
      const payload = { input: { schedules: scheduleUpdates } };
      await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        },
        "schedules_archive",
        onSuccessfulModification
      );
      setSelected([]);
    } catch (error) {
      console.log("Bulk update action cancelled", error);
    }
  };

  // Bulk Delete: Delete selected archived schedules.
  const handleDeleteSelected = async () => {
    if (selected.length === 0) return;
    try {
      await requestConfirm(
        "Are you sure you want to delete the selected archived schedules?"
      );
      const schedulesToDelete = sortedData
        .filter((schedule) => selected.includes(schedule.uid))
        .map((schedule) => schedule);
      const url = process.env.REACT_APP_DATABASE_URL;
      const object = {
        command: "command_any_table",
        obj: {
          command: "delete_items",
          item: schedulesToDelete,
          table_name: "schedules_archive",
        },
      };
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(object),
        },
        "schedules_archive",
        onSuccessfulModification
      );
      if (result.success) setSelected([]);
    } catch (error) {
      console.log("Delete action cancelled", error);
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelected(sortedData.map((schedule) => schedule.uid));
    } else {
      setSelected([]);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const clearAllFilters = () => {
    setTimeFilter("");
    setEventFilter("");
    setNumberFilter("");
    setTaskFilter("");
    setTypeFilter("");
    setFilteredSchedules(schedules);
  };

  return (
    <Card style={{ minHeight: "120vh", margin: "0px", padding: "0px" }}>
      <div style={{ padding: "0px" }}>
        {ConfirmDialog}

        {/* Bulk Actions and Filters */}
        <Box m={2} display="flex" flexDirection="column" gap={2}>
          {/* Filters */}
          <Box display="flex" gap={2} flexWrap="wrap">
            <TextField
              style={{ minWidth: "200px" }}
              label="Filter by Time"
              variant="outlined"
              value={timeFilter}
              onChange={(e) => {
                setTimeFilter(e.target.value);
                handleFilterChange();
              }}
              placeholder="(MM/DD/YYYY HH:mm)"
            />

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Event</InputLabel>
              <Select
                value={eventFilter}
                onChange={(e) => setEventFilter(e.target.value)}
                label="Event"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {events.map((event) => (
                  <MenuItem key={event} value={event}>
                    {event}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Number</InputLabel>
              <Select
                value={numberFilter}
                onChange={(e) => setNumberFilter(e.target.value)}
                label="Number"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {numbers.map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Task</InputLabel>
              <Select
                value={taskFilter}
                onChange={(e) => setTaskFilter(e.target.value)}
                label="Task"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {tasks.map((task) => (
                  <MenuItem key={task} value={task}>
                    {task
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                label="Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Tooltip title="Clear Filters">
              <IconButton onClick={clearAllFilters} aria-label="clear">
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <TableContainer component={Card}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Box display="flex" alignItems="center" gap={2} ml={4}>
            {/* Bulk Action Buttons */}
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "20px",
                minWidth: "190px",
              }}
              variant="contained"
              color="secondary"
              onClick={handleBulkRun}
              disabled={selected.length === 0}
            >
              <BoltIcon />
              Run again (in 2 min)
            </Button>

            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "20px",
                minWidth: "190px",
              }}
              variant="contained"
              color="primary"
              onClick={handleBulkUpdateTime}
              disabled={selected.length === 0 || !bulkNewTime}
            >
              <PublishIcon /> Rerun
            </Button>

            <CustomDateTimePicker
              onConfirm={(date, tz) => {
                setBulkNewTime(date);
                setBulkNewTimezone(tz);
              }}
              edgyButton={true}
            />

            <Box
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "5px",
                minWidth: "290px",
              }}
            >
              {bulkNewTime && (
                <Typography variant="body2">
                  New selected time:{" "}
                  {translateUnixExpression(
                    bulkNewTime,
                    bulkNewTimezone || timezone
                  )}
                </Typography>
              )}
            </Box>
            {/* Delete Selected Button */}
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "auto",
                marginRight: "15px",
              }}
              variant="contained"
              color="error"
              onClick={handleDeleteSelected}
              disabled={selected.length === 0}
            >
              <DeleteIcon />
            </Button>
          </Box>
          <Table aria-label="archive table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < sortedData.length
                      }
                      checked={
                        sortedData.length > 0 &&
                        selected.length === sortedData.length
                      }
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                    <Typography>{JSON.stringify(selected.length)}</Typography>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "schedule_description"}
                    direction={
                      orderBy === "schedule_description"
                        ? orderDirection
                        : "asc"
                    }
                    onClick={() => handleRequestSort("schedule_description")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Description />
                      <Typography variant="body1">
                        <strong>Schedule</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "execution_time"}
                    direction={
                      orderBy === "execution_time" ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort("execution_time")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon />
                      <Typography variant="body1">
                        <strong>Time</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "input"}
                    direction={orderBy === "input" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("input")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <DataObjectIcon />
                      <Typography variant="body1">
                        <strong>Input</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "response"}
                    direction={orderBy === "response" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("response")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <DataObjectIcon />
                      <Typography variant="body1">
                        <strong>Response</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Nirvana />
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((schedule, index) => (
                  <ArchivedScheduleRow
                    key={schedule.uid}
                    schedule={schedule}
                    timezone={timezone}
                    rowIndex={index}
                    selected={selected.includes(schedule.uid)}
                    onSelectChange={(checked) => {
                      if (checked) {
                        setSelected((prev) => [...prev, schedule.uid]);
                      } else {
                        setSelected((prev) =>
                          prev.filter((id) => id !== schedule.uid)
                        );
                      }
                    }}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
}

export default ArchivedScheduleTable;
