import React, { useState, useContext, useEffect } from "react";
import RouteSource from "./RouteSource";
import RouteDestination from "./RouteDestination";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Box, Typography } from "@mui/material";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { useTheme, styled } from "@mui/material/styles";

export default function GatewayRoute({
  route,
  host,
  index,
  gateway,
  triggerReload,
}) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const isOdd = index % 2 !== 0;
  const theme = useTheme();

  const handleDelete = async (item) => {
    try {
      await requestConfirm("Are you sure you want delete this route? " + item);
      let url =
        process.env.REACT_APP_TASKS_URL +
        "/components/exchange/gateway/delete_gateways_routes";
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: { routes: [{ route_name: item }], gateways: [gateway] },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };
  return (
    <div>
      {ConfirmDialog}
      <Box
        display="flex"
        justifyContent={"left"}
        alignItems={"center"}
        gap="10px"
        p={3}
        style={{
          backgroundColor: isOdd
            ? theme.custom.rowColors.odd
            : theme.custom.rowColors.even,
        }}
      >
        <Typography variant="h5" gutterBottom ml={3}>
          Route: {route.name}
        </Typography>
        <Typography variant="body1" p={0}>
          State: {route.state}
        </Typography>
        <IconButton
          style={{ marginLeft: "auto" }}
          aria-label="delete"
          onClick={() => handleDelete(route.name)}
        >
          <DeleteIcon color="secondary" className="icon" />
        </IconButton>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            style={{
              display: "flex",
              gap: "30px",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              Source: {route.source.name}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              Status: {route.source.state}
            </Typography>
          </Box>
          <div style={{ marginLeft: "auto", marginRight: "50px" }}>
            {route.source.state == "connected" ? (
              <CastConnectedIcon color="success"></CastConnectedIcon>
            ) : (
              <WarningIcon color="warning"></WarningIcon>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <RouteSource source={route.source} />
        </AccordionDetails>
      </Accordion>
      {/* Using Accordion for each destination */}
      {route.destinations.map((dest, index) => (
        <Accordion
          key={index}
          style={{
            backgroundColor:
              index % 2 !== 0
                ? theme.custom.rowColors.odd
                : theme.custom.rowColors.even,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left", // Spread items across the width
                alignItems: "center", // Align items vertically in the center
                gap: "30px", // Maintain a gap between items
                width: "100%", // Ensure the Box takes up the full width
              }}
            >
              <Typography style={{ minWidth: "20%" }}>
                Destination: {dest.name}
              </Typography>
              <Typography>State: {dest.state}</Typography>
              <Typography>Action state: {dest.summaryStatusDetails}</Typography>
            </Box>
            <div style={{ marginLeft: "auto", marginRight: "50px" }}>
              {dest.state == "connected" ? (
                <CastConnectedIcon color="success"></CastConnectedIcon>
              ) : (
                <WarningIcon color="warning"></WarningIcon>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <RouteDestination
              destState={dest.state}
              destination={dest}
              host={host}
              index={index}
              routeName={route.name}
              gateway={gateway}
              triggerReload={triggerReload}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
