import React, { useContext } from "react";
import { Button, Card, CardContent, Typography, Box } from "@mui/material";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import DistributionsBox from "../Utility/DistributionsBox";

export default function MedialiveService({ service, region, filter }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleStart = async (service) => {
    try {
      await requestConfirm(
        "Are you sure you want to start this service? " + service.event_name
      );
      let url =
        process.env.REACT_APP_TASKS_URL +
        "/components/mix/medialive/start_medialive_service";
      await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: {
              services_ids: [
                {
                  channel_id: service.channel_id,
                  region: region,
                },
              ],
            },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log("Update action cancelled by user:", error);
    }
  };

  const handleStop = async (service) => {
    try {
      await requestConfirm(
        "Are you sure you want to stop this service? " + service.event_name
      );
      let url =
        process.env.REACT_APP_TASKS_URL +
        "/components/mix/medialive/stop_medialive_service";
      await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: {
              services_ids: [
                {
                  channel_id: service.channel_id,
                  region: region,
                },
              ],
            },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log("Update action cancelled by user:", error);
    }
  };

  return (
    <div>
      {ConfirmDialog}
      {Object.keys(filter).length === 0 ||
      service.tags?.mix_alias?.includes(filter?.filter) ? (
        <Card
          variant="outlined"
          sx={{ marginBottom: 2, padding: 2, borderRadius: "4px" }}
        >
          <CardContent>
            <Typography variant="h6" component="div" gutterBottom>
              {service.event_name.replace(/_/g, " ")}{" "}
            </Typography>

            <Typography color="text.secondary" gutterBottom>
              <strong>Channel ID:</strong> {service.channel_id}
            </Typography>

            <Typography color="text.secondary" gutterBottom>
              <strong>Region:</strong> {region}
            </Typography>

            <Typography color="text.secondary" gutterBottom>
              <strong>State:</strong> {service.state}
            </Typography>
            <Box display="flex">
              <Typography color="text.secondary" gutterBottom>
                <strong>Distribution:</strong>{" "}
              </Typography>
              <DistributionsBox
                distributions={[service.tags.distribution]}
              ></DistributionsBox>
            </Box>

            {service.tags && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Tags:</strong>
                </Typography>
                {Object.entries(service.tags).map(([key, value]) => (
                  <Typography
                    key={key}
                    color={value.includes("FCO") ? "error" : "text.secondary"} // Red if "FCO" is in value
                    sx={{ ml: 2 }}
                  >
                    <strong>{key}:</strong> {value}
                  </Typography>
                ))}
              </>
            )}
            <Box display="flex" gap={"10px"}>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => handleStart(service)}
                disabled={service.state !== "IDLE"}
              >
                Start
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={() => handleStop(service)}
                disabled={service.state !== "RUNNING"}
              >
                Stop
              </Button>
            </Box>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}
