import * as React from "react";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ReactJson from "react-json-view";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { sanitizeJSON } from "./sanitizeJSON";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "70vw",
    width: "70vw",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

// Placeholder JSONForm Component
const JSONForm = ({ jsonData, onJsonChange }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="body1">JSON Form Placeholder</Typography>
      <Typography variant="body2">TBD</Typography>
    </Box>
  );
};

export default function JSONDialog({
  input_json,
  name,
  enableEdit,
  handleNewJSON,
}) {
  const parseInput = (input) => {
    if (typeof input === "object" && input !== null) {
      return input;
    }
    try {
      return JSON.parse(input);
    } catch (error) {
      console.error("Error parsing input:", error);
      return {};
    }
  };

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editedJson, setEditedJson] = useState(parseInput(input_json));
  const [tabIndex, setTabIndex] = useState(0); // State to track active tab

  useEffect(() => {
    setEditedJson(parseInput(input_json));
  }, [input_json]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (edit) => {
    setEditedJson(edit.updated_src);
  };

  const handleSubmit = () => {
    handleNewJSON(editedJson);
    setOpen(false);
  };

  const isInputJsonEmptyOrNull = (input) => {
    return (
      !input ||
      (Array.isArray(input) && input.length === 0) ||
      (Object.keys(input).length === 0 && input.constructor === Object)
    );
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        style={
          !(isInputJsonEmptyOrNull(editedJson) && !enableEdit)
            ? {
                borderColor: theme.palette.text.primary,
                color: theme.palette.text.primary,
              }
            : {}
        }
        disabled={isInputJsonEmptyOrNull(editedJson) && !enableEdit}
        onClick={handleClickOpen}
      >
        <DataObjectIcon />
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {name}
          <Tooltip title="Copy to clipboard">
            <IconButton
              onClick={() => copyToClipboard(JSON.stringify(editedJson))}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Tabs for switching between JSON Viewer & JSON Form */}
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => setTabIndex(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="JSON Viewer" />
          {/* <Tab label="JSON Form" /> */}
        </Tabs>

        <DialogContent dividers>
          {tabIndex === 0 ? (
            <ReactJson
              src={editedJson}
              theme={theme.palette.mode === "dark" ? "monokai" : "rjv-default"}
              collapsed={true}
              enableClipboard={({ value }) =>
                copyToClipboard(JSON.stringify(value))
              }
              onEdit={enableEdit ? handleEdit : false}
              onAdd={enableEdit ? handleEdit : false}
              onDelete={enableEdit ? handleEdit : false}
              sortKeys={true}
            />
          ) : (
            <JSONForm jsonData={editedJson} onJsonChange={setEditedJson} />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          {enableEdit && (
            <Button onClick={handleSubmit} variant="contained">
              Set
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
