import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import JsonImporter from "../../Utility/JsonImporter";
import Typography from "@mui/material/Typography";

const AddGatewayForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);

  // Initialize the form data based on the schema
  const [formData, setFormData] = useState(() => {
    const initialData = { uid: uuidv4() };
    Object.keys(schema).forEach((key) => {
      if (key !== "uid") {
        initialData[key] = schema[key].type === "array" ? [] : "";
      }
    });
    return initialData;
  });

  const [selectedDistribution, setSelectedDistribution] = useState("");
  const [error, setError] = useState("");

  // Helper: returns the port number (as a string) for a given distribution.
  // Distributions sharing the same port number cannot be added together.
  const getPortForDistribution = (distribution) => {
    switch (distribution) {
      case "A":
      case "J":
      case "S":
        return "1";
      case "B":
      case "K":
      case "T":
        return "2";
      case "C":
      case "L":
      case "U":
        return "3";
      case "D":
      case "M":
      case "V":
        return "4";
      case "E":
      case "N":
      case "W":
        return "5";
      case "F":
      case "O":
      case "X":
        return "6";
      case "G":
      case "P":
      case "Y":
        return "7";
      case "H":
      case "Q":
      case "Z":
        return "8";
      case "I":
      case "R":
        return "9";
      default:
        console.error("No distribution mapping found for", distribution);
        throw new Error(
          "No distribution mapping found for distribution: " + distribution
        );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setError("");
  };

  const handleDistributionChange = (event) => {
    setSelectedDistribution(event.target.value);
  };

  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  // Modified to check for conflicting port numbers.
  const handleAddDistribution = () => {
    if (selectedDistribution) {
      // Check if this distribution is already added
      if (formData.distributions.includes(selectedDistribution)) {
        setError(`${selectedDistribution} is already added.`);
        return;
      }
      const newPort = getPortForDistribution(selectedDistribution);
      // Look for any distribution already added that maps to the same port.
      const conflict = formData.distributions.find(
        (dist) => getPortForDistribution(dist) === newPort
      );
      if (conflict) {
        setError(
          `Cannot add ${selectedDistribution} because it conflicts with ${conflict}.`
        );
        return;
      }
      // No conflict found; add the new distribution.
      setFormData({
        ...formData,
        distributions: [...formData.distributions, selectedDistribution],
      });
      setSelectedDistribution("");
      setError("");
    }
  };

  const handleRemoveDistribution = (distribution) => {
    setFormData({
      ...formData,
      distributions: formData.distributions.filter(
        (distribution_item) => distribution_item !== distribution
      ),
    });
  };

  const validateData = () => {
    let isValid = true;
    Object.keys(schema).forEach((key) => {
      if (
        schema[key].required &&
        (formData[key] === "" ||
          (Array.isArray(formData[key]) && formData.distributions.length === 0))
      ) {
        isValid = false;
      }
    });

    if (!isValid) {
      setError("All required fields must be filled.");
      return false;
    }
    setError("");
    return true;
  };

  const handleClose = () => {
    const initialData = { uid: uuidv4() };
    Object.keys(schema).forEach((key) => {
      if (key !== "uid") {
        initialData[key] = schema[key].type === "array" ? [] : "";
      }
    });
    setFormData(initialData);
    setSelectedDistribution("");
    setError("");
    onClose();
  };

  const handleSubmit = () => {
    if (validateData()) {
      onAdd(formData);
      handleClose(); // Reset form with a new UID and clear fields after submission
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", padding: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1">Add New Gateway Item</Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {Object.keys(schema).map((key) =>
          key !== "distributions" ? (
            <TextField
              key={key}
              autoFocus={key === "uid"}
              margin="dense"
              name={key}
              label={
                key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")
              }
              type="text"
              fullWidth
              variant="outlined"
              value={formData[key]}
              onChange={handleChange}
              disabled={key === "uid"}
            />
          ) : null
        )}
        <FormControl fullWidth margin="dense">
          <InputLabel id="distribution-select-label">Distributions</InputLabel>
          <Select
            labelId="distribution-select-label"
            label="Distributions"
            id="distribution-select"
            value={selectedDistribution}
            onChange={handleDistributionChange}
            fullWidth
          >
            {state.database.distributions
              .slice() // Create a shallow copy
              .sort((a, b) => {
                // Sort alphabetically using alias (or uid if alias is missing)
                const nameA = a.alias || a.uid;
                const nameB = b.alias || b.uid;
                return nameA.localeCompare(nameB);
              })
              .map((option) => (
                <MenuItem key={option.uid} value={option.distribution}>
                  {option.alias}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleAddDistribution}
          variant="contained"
          style={{ marginTop: "1rem" }}
        >
          Add Distribution
        </Button>
        <Box display="flex" flexWrap="wrap" gap={1} marginTop={2}>
          {formData.distributions.map((distribution_item, index) => (
            <Chip
              key={index}
              label={distribution_item}
              onDelete={() => handleRemoveDistribution(distribution_item)}
              color="primary"
              style={{ margin: "5px" }}
            />
          ))}
        </Box>
        {error && (
          <Alert severity="error" style={{ marginTop: "1rem" }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGatewayForm;
