import React, { useState, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  LinearProgress,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import Gateway from "./Gateway";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

export default function Gateways() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedDistributions, setSelectedDistributions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const handleStart = async (gateways) => {
    try {
      await requestConfirm("Are you sure?");
      let url = process.env.REACT_APP_START_GATEWAYS_URL;
      await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ gateways }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log("Update action cancelled by user", error);
    }
  };

  const handleStop = async (gateways) => {
    try {
      await requestConfirm("Are you sure?");
      let url = process.env.REACT_APP_STOP_GATEWAYS_URL;
      await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ gateways }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log("Update action cancelled by user", error);
    }
  };

  const regions = [
    ...new Set(state.database.gateways?.map((g) => g.region) || []),
  ].sort((a, b) => a.localeCompare(b));

  const distributions = [
    ...new Set(state.database.gateways?.flatMap((g) => g.distributions) || []),
  ].sort((a, b) => a.localeCompare(b));

  const filteredGateways = state.database.gateways?.filter((gateway) => {
    const matchesRegion =
      selectedRegions.length === 0 || selectedRegions.includes(gateway.region);
    const matchesDistribution =
      selectedDistributions.length === 0 ||
      gateway.distributions?.some((d) => selectedDistributions.includes(d));
    return matchesRegion && matchesDistribution;
  });

  return (
    <div style={{ padding: "10px" }}>
      {ConfirmDialog}
      <Box sx={{ display: "flex", alignItems: "bottom" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleStart(filteredGateways)}
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "0px",
            marginLeft: "10px",
            width: "200px",
          }}
        >
          Start All {filteredGateways.length}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleStop(filteredGateways)}
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopLeftRadius: "0px",
            width: "200px",
          }}
        >
          Stop All {filteredGateways.length}
        </Button>

        <FormControl
          sx={{
            minWidth: "450px",
            marginLeft: "60px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px 5px 0px 0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: "none",
            },
            "& .MuiSelect-icon": {
              color: (theme) => theme.palette.primary.contrastText,
            },
          }}
        >
          <InputLabel>Regions</InputLabel>
          <Select
            label="Regions"
            multiple
            value={selectedRegions}
            onChange={(e) => setSelectedRegions(e.target.value)}
          >
            {regions.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            minWidth: "450px",
            marginLeft: "20px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px 5px 0px 0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: "none",
            },
            "& .MuiSelect-icon": {
              color: (theme) => theme.palette.primary.contrastText,
            },
          }}
        >
          <InputLabel>Distributions</InputLabel>
          <Select
            label="Distributions"
            multiple
            value={selectedDistributions}
            onChange={(e) => setSelectedDistributions(e.target.value)}
          >
            {distributions.map((distribution) => (
              <MenuItem key={distribution} value={distribution}>
                {distribution}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {filteredGateways
        ?.sort((a, b) => {
          const regionComparison = a.region.localeCompare(b.region);
          if (regionComparison === 0) {
            const extractNumber = (hostname) => {
              const match = hostname.match(/(\d+)/);
              return match ? parseInt(match[0], 10) : 0;
            };
            return extractNumber(a.host) - extractNumber(b.host);
          }
          return regionComparison;
        })
        .map((gateway, index) => (
          <Gateway gateway={gateway} key={index} index={index} />
        ))}
    </div>
  );
}
