import React, { useState, useContext, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useCronTranslator from "../Utility/useCronTranslator";
import { useApiRequest } from "../Utility/useAPIRequest";
import moment from "moment-timezone";
import { MyContext } from "../../context/Context";
import JSONDialog from "../Utility/JSONDialog";
import { useTheme, styled } from "@mui/material/styles";
import DistributionsBox from "../Utility/DistributionsBox";

const ScheduleRow = ({
  schedule,
  timezone,
  rowIndex,
  selected,
  onSelectChange,
}) => {
  const { onSuccessfulModification } = useContext(MyContext);
  const translateCronExpression = useCronTranslator(timezone);
  const theme = useTheme();
  const isOdd = rowIndex % 2 !== 0;
  const [timeLeft, setTimeLeft] = useState(null);
  const [isOverdue, setIsOverdue] = useState(false);

  // Pulsating animation for AccessTimeIcon when timeLeft is <= 5
  const PulsatingIcon = styled(AccessTimeIcon)(({ theme }) => ({
    animation: "pulse 1.5s ease-in-out infinite",
    "@keyframes pulse": {
      "0%": { transform: "scale(1)" },
      "50%": { transform: "scale(1.4)" },
      "100%": { transform: "scale(1)" },
    },
  }));

  // Convert cron expression to Unix timestamp in ms.
  const cronToUnixMs = (cronExpression) => {
    const cronParts = cronExpression
      .replace("cron(", "")
      .replace(")", "")
      .split(" ");
    const [minutes, hours, day, month, , year] = cronParts;
    const date = moment.utc({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
      hour: Number(hours),
      minute: Number(minutes),
    });
    return date.isValid() ? date.valueOf() : NaN;
  };

  const scheduleTimeMs = cronToUnixMs(schedule.ScheduleExpression);

  useEffect(() => {
    const updateRemainingTime = () => {
      const currentTime = moment.utc().valueOf();
      const timeDifferenceMinutes = (scheduleTimeMs - currentTime) / 60000;
      setTimeLeft(Math.abs(Math.ceil(timeDifferenceMinutes)));
      setIsOverdue(timeDifferenceMinutes < 0);
    };

    updateRemainingTime();
    const intervalId = setInterval(updateRemainingTime, 15000);
    return () => clearInterval(intervalId);
  }, [scheduleTimeMs]);

  return (
    <React.Fragment>
      <TableRow
        style={{
          backgroundColor: isOdd
            ? theme.custom.rowColors.odd
            : theme.custom.rowColors.even,
        }}
      >
        {/* Selection Checkbox */}
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onChange={(e) => onSelectChange(e.target.checked)}
          />
        </TableCell>
        <TableCell></TableCell>
        {/* Schedule Details */}
        <TableCell component="th" scope="row">
          {(() => {
            const [event, number, task, type, index, distribution] =
              schedule.Description.split(";");
            return (
              <Box display="flex" gap="15px">
                <Chip
                  label={
                    <Typography style={{ fontWeight: "bold" }}>
                      {event}
                    </Typography>
                  }
                  size="medium"
                />
                <Chip label={number} size="medium" />
                <Chip
                  variant="outlined"
                  style={{ minWidth: "150px" }}
                  label={
                    <Typography style={{ fontWeight: "bold" }}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Typography>
                  }
                  size="medium"
                />
                <Chip
                  label={task
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  size="medium"
                  variant="outlined"
                  style={{ minWidth: "250px" }}
                />
                <Chip label={index} size="medium" variant="outlined" />
                <DistributionsBox distributions={[distribution]} />
              </Box>
            );
          })()}
        </TableCell>

        {/* Remaining Time Icon and Text */}
        <TableCell align="left">
          {timeLeft !== null && (
            <Box display="flex" alignItems="center" gap="10px">
              {timeLeft <= 5 ? (
                <PulsatingIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              ) : (
                <AccessTimeIcon style={{ color: theme.palette.warning.main }} />
              )}
              {timeLeft < 270 ? (
                <Typography variant="body2">{`${timeLeft} minutes ${
                  isOverdue ? "ago" : "remaining"
                }`}</Typography>
              ) : (
                <Typography variant="body2">Scheduled</Typography>
              )}
            </Box>
          )}
        </TableCell>

        {/* Translated Schedule Time */}
        <TableCell>
          {translateCronExpression(schedule.ScheduleExpression)}
        </TableCell>

        {/* JSONDialog for Input */}
        <TableCell component="th" scope="row">
          <JSONDialog input_json={schedule.Target.Input} name={schedule.Name} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ScheduleRow;
