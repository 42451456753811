import { Grid, Typography, Link, Paper, Box } from "@mui/material";
import React from "react";
import DnsIcon from "@mui/icons-material/Dns";

export default function GatewayConfig({ exchangeConfig }) {
  return (
    <div style={{ marginTop: "10px" }}>
      {/* <Typography>{JSON.stringify(exchangeConfig)}</Typography> */}
      {exchangeConfig.api_config.gateways.map((gateway, index) => (
        <Box pt={2}>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <DnsIcon color={"disabled"} />
            <Link href={`https://${gateway["host"]}`} underline="hover">
              {gateway["host"]}
            </Link>
          </Box>
          <Typography>Path: {gateway["path"]}</Typography>
        </Box>
      ))}
      <br></br>
      {exchangeConfig.api_config.routes.map((route, index) => (
        <Box display="flex" flexDirection={"column"} mt={2}>
          <Typography variant="h6" index>
            {route.route_name}
          </Typography>
          <Typography index>
            Destinations: {route.destinations.length}
          </Typography>
        </Box>
      ))}
    </div>
  );
}
