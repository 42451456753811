// src/components/Gateway.jsx
import React, { useState, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button, Paper, Grid, Link, Box } from "@mui/material";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import LinearProgress from "@mui/material/LinearProgress";
import GatewayConfig from "./GatewayConfig";
import ReplayIcon from "@mui/icons-material/Replay";
import GatewayDetails from "./GatewayDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import PublicIcon from "@mui/icons-material/Public";
import DistributionsBox from "../Utility/DistributionsBox";
import DistributionEditorDialog from "../Utility/DistributionEditorDialog";
import DnsIcon from "@mui/icons-material/Dns";
import { useTheme } from "@mui/material/styles";

export default function Gateway({ gateway, index }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const [routesInfo, setRoutesInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDistEditor, setOpenDistEditor] = useState(false);
  const theme = useTheme();

  const handleDeleteAll = async () => {
    try {
      await requestConfirm("Are you sure you want delete all routes?");
      let url =
        process.env.REACT_APP_TASKS_URL +
        "/components/exchange/gateway/delete_gateways_routes";
      let allRoutes = [];
      routesInfo[0][0]?.config.forEach((element) => {
        allRoutes.push({ route_name: element.name });
      });
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: { routes: allRoutes, gateways: [gateway] },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStart = async (gateways) => {
    try {
      await requestConfirm("Are you sure you want to start this instance?");
      let url = process.env.REACT_APP_START_GATEWAYS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gateways: gateways,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStop = async (gateways) => {
    try {
      await requestConfirm("Are you sure you want to stop this instance?");
      let url = process.env.REACT_APP_STOP_GATEWAYS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gateways: gateways,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const getGatewayRouteInfo = async (gateway) => {
    setLoading(true);
    try {
      let url = process.env.REACT_APP_STATUS_URL;
      const result = await makeApiRequest(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          command: "get_gateways_routes_status",
          input: { gateways: [gateway] },
        }),
      });
      console.log(result);
      setRoutesInfo(result.data.response);
      return;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    } finally {
      setLoading(false);
    }
  };

  const gatewayStatus = state.status.gateways?.find((gateway_status) =>
    gateway_status.InstanceStatuses.some(
      (instance) => instance.InstanceId === gateway["aws_instance_id"]
    )
  );
  const instanceState = gatewayStatus ? (
    <Typography>
      {gatewayStatus.InstanceStatuses[0].InstanceState.Name}
    </Typography>
  ) : (
    <LinearProgress style={{ width: "50px" }} />
  );

  return (
    <div>
      {ConfirmDialog}
      <Accordion
        key={index}
        style={{
          backgroundColor:
            index % 2 !== 0
              ? theme.custom.rowColors.odd
              : theme.custom.rowColors.even,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <DesktopWindowsIcon
              color={
                gatewayStatus &&
                gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                  "running"
                  ? "primary"
                  : "secondary"
              }
            />
            <Typography style={{ minWidth: "270px" }}>
              {gateway["host"]}
            </Typography>
            <Box sx={{ display: "flex", minWidth: "120px" }}>
              {instanceState}
            </Box>
            {gateway["distributions"] && (
              <DistributionsBox distributions={gateway["distributions"]} />
            )}
            <Box sx={{ display: "flex", minWidth: "100px" }}>
              <Button
                style={{ minWidth: "100px" }}
                color={gateway["path"] === "main" ? "secondary" : "primary"}
                disabled={true}
                variant={
                  gatewayStatus &&
                  gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                    ? "contained"
                    : "outlined"
                }
              >
                {gateway["path"]}
              </Button>
            </Box>
            <Box sx={{ display: "flex", gap: "5px", minWidth: "300px" }}>
              <DnsIcon color={"disabled"} />
              <Link href={`https://${gateway["host"]}`} underline="hover">
                {gateway["host"]}
              </Link>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "10px",
                borderTopRightRadius: "0px",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleStart([gateway])}
              disabled={
                !(
                  gatewayStatus &&
                  gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                    "stopped"
                )
              }
            >
              Start
            </Button>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopLeftRadius: "0px",
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleStop([gateway])}
              disabled={
                !(
                  gatewayStatus &&
                  gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                )
              }
            >
              Stop
            </Button>{" "}
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "100px",
              }}
              variant="contained"
              color="secondary"
              onClick={() => setOpenDistEditor(true)}
            >
              Edit Distributions
            </Button>
            {/* Distribution Editor Dialog */}
            <DistributionEditorDialog
              open={openDistEditor}
              onClose={() => setOpenDistEditor(false)}
              tableName="gateways"
              currentItem={gateway}
              availableDistributions={state.database.distributions}
              onSaveSuccess={(updatedItem) => {
                onSuccessfulModification();
              }}
            />
          </div>
          <Paper style={{ padding: "20px", marginBottom: 20 }}>
            <GatewayDetails gateway={gateway} />
            {/* Button to open the DistributionEditor dialog */}

            <div>
              <Button
                style={{
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  marginTop: "50px",
                  marginLeft: "10px",
                }}
                variant="contained"
                color="primary"
                onClick={() => getGatewayRouteInfo(gateway)}
                disabled={
                  !(
                    gatewayStatus &&
                    gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                      "running"
                  )
                }
              >
                {!routesInfo ? (
                  <Box style={{ display: "flex" }}>
                    <Typography>Load Routes</Typography>
                    <ExpandMoreIcon className="icon" />
                  </Box>
                ) : (
                  <ReplayIcon className="icon" />
                )}
              </Button>

              <Button
                style={{
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  marginTop: "50px",
                  marginLeft: "75%",
                }}
                variant="contained"
                color="primary"
                onClick={() => {}}
                disabled={true}
              >
                Export routes to spreadsheet
              </Button>
              {!routesInfo ? null : (
                <Button
                  style={{
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    marginTop: "50px",
                    marginLeft: "85%",
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteAll()}
                  disabled={
                    !(
                      gatewayStatus &&
                      gatewayStatus.InstanceStatuses &&
                      gatewayStatus.InstanceStatuses[0] &&
                      gatewayStatus.InstanceStatuses[0].InstanceState &&
                      gatewayStatus.InstanceStatuses[0].InstanceState.Name ===
                        "running"
                    ) ||
                    (routesInfo &&
                      routesInfo[0] &&
                      routesInfo[0][0] &&
                      routesInfo[0][0].config &&
                      routesInfo[0][0].config.length === 0)
                  }
                >
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Typography>Delete All Routes</Typography>
                    <DeleteIcon className="icon" />
                  </Box>
                </Button>
              )}
            </div>
            {loading && <LinearProgress />}
            {routesInfo && (
              <GatewayConfig
                configs={routesInfo[0]}
                gateway={gateway}
                triggerReload={() => getGatewayRouteInfo(gateway)}
              />
            )}
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
