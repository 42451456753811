import { fetchAuthSession } from "aws-amplify/auth";

/**
 * Fetches a thumbnail from AWS Lambda while handling authentication & binary response.
 * @param {string} ffmpegInput - The video stream input for FFmpeg.
 * @returns {Promise<{ success: boolean, data?: { thumbnail: string, metadata: object }, error?: object }>}
 */
export const fetchThumbnailApi = async (ffmpegInput) => {
  try {
    // 🔹 Fetch user session & token
    const session = await fetchAuthSession();
    const validToken = session.tokens?.accessToken;

    if (!validToken) {
      return { success: false, error: { message: "Invalid or expired token" } };
    }

    const url = process.env.REACT_APP_THUMBNAIL_GENERATOR_URL;
    const requestBody = { ffmpeg_input: ffmpegInput };

    // 🔹 Send the API request
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${validToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    // 🔹 Handle HTTP errors and extract detailed error info
    if (!response.ok) {
      let errorDetails = {
        message: `Error ${response.status}: ${response.statusText}`,
      };

      try {
        const errorData = await response.json();
        errorDetails = {
          error: errorData.error || "Unknown API Error",
          message: errorData.message || errorDetails.message,
          ffmpeg_output: errorData.ffmpeg_output || null,
        };
      } catch (jsonParseError) {
        console.error("Failed to parse error response:", jsonParseError);
      }

      return { success: false, error: errorDetails };
    }

    // 🔹 Parse JSON response
    const jsonResponse = await response.json();

    if (!jsonResponse.thumbnail || !jsonResponse.isBase64Encoded) {
      return {
        success: false,
        error: { message: "Invalid image data received" },
      };
    }

    return {
      success: true,
      data: {
        thumbnail: `data:image/jpeg;base64,${jsonResponse.thumbnail}`,
        metadata: jsonResponse.metadata || "No metadata available",
      },
    };
  } catch (error) {
    console.error("Thumbnail API error:", error);
    return {
      success: false,
      error: { message: error.message || "Unknown error occurred" },
    };
  }
};
