// EventsTable.jsx
import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../../context/Context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
  Checkbox,
} from "@mui/material";
import StadiumIcon from "@mui/icons-material/Stadium";
import SportsIcon from "@mui/icons-material/Sports";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import moment from "moment-timezone";
import { useTheme } from "@mui/material/styles";
import AddItemFormRouting from "../Database/AddForms/ItemAddFormRouting";
import schemas from "../Database/schemas.json";
import EventRow from "./EventRow";
import { useApiRequest } from "../Utility/useAPIRequest";
import useConfirm from "../Utility/useConfirm";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import TodayIcon from "@mui/icons-material/Today";
import HistoryIcon from "@mui/icons-material/History";
import UpdateIcon from "@mui/icons-material/Update";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";

export default function EventsTable() {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("start_time");
  const [addItemOpen, setAddItemOpen] = useState(false);
  const [selectedTournament, setSelectedTournament] =
    useState("All Tournaments");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [paginatedEvents, setPaginatedEvents] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [addItemType, setAddItemType] = useState("event");
  const currentSchema = schemas.tables["events"]?.item_schema;
  const theme = useTheme();
  const { makeApiRequest } = useApiRequest();
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { warmupLambdas } = useApiRequest();
  const [eventFilter, setEventFilter] = useState("today");
  const warmUpUrls = [
    process.env.REACT_APP_CREATE_SCHEDULE_URL,
    process.env.REACT_APP_DATABASE_URL,
    process.env.REACT_APP_TASKS_URL + "/warmup_tasks",
    process.env.REACT_APP_STATUS_URL,
  ];
  // Helper: determine if an event is past
  const isEventPast = (endTime) => {
    const now = moment();
    const endMoment = moment(Number(endTime));
    return now.isAfter(endMoment);
  };

  // Helper: check for reference errors (similar to EventRow)
  const hasReferenceError = (event) => {
    const itemSchema = schemas.tables.events.item_schema;
    for (const key in itemSchema) {
      if (itemSchema[key].tableReference) {
        const referencedTable = itemSchema[key].tableReference;
        const refExists = state.database[referencedTable]?.some(
          (item) => item.uid === event[key]
        );
        if (!refExists) return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let events = state.database.events || [];

    const now = moment();
    const oneDayLater = moment().add(1, "day");
    const todayStart = moment().startOf("day");
    const todayEnd = moment().endOf("day");

    events = events.filter((event) => {
      const startTime = moment(Number(event.start_time));
      const endTime = moment(Number(event.end_time));

      if (eventFilter === "today") {
        return startTime.isBetween(todayStart, todayEnd, null, "[]"); // inclusive
      }
      if (eventFilter === "future") {
        return startTime.isAfter(now);
      }

      if (eventFilter === "past") {
        return endTime.isBefore(now);
      }

      return true; // "all"
    });

    // Tournament filter
    if (selectedTournament !== "All Tournaments") {
      events = events.filter(
        (event) => event.tournament_uid === selectedTournament
      );
      setPage(0);
    }

    // Sort
    const sortedEvents = [...events].sort(
      (a, b) =>
        (orderDirection === "asc" ? 1 : -1) * (a[orderBy] < b[orderBy] ? -1 : 1)
    );

    // Paginate
    const startIndex = page * rowsPerPage;
    const paginated = sortedEvents.slice(startIndex, startIndex + rowsPerPage);

    setFilteredEvents(sortedEvents);
    setPaginatedEvents(paginated);
  }, [
    state.database.events,
    orderBy,
    orderDirection,
    selectedTournament,
    page,
    rowsPerPage,
    eventFilter, // 👈 new unified filter
  ]);

  // Handle selection
  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = filteredEvents.map((n) => n.uid);
      setSelectedEventIds(newSelecteds);
      return;
    }
    setSelectedEventIds([]);
    warmupLambdas(warmUpUrls);
  };

  const handleRowClick = (uid) => {
    const selectedIndex = selectedEventIds.indexOf(uid);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEventIds, uid);
    } else {
      newSelected = selectedEventIds.filter((id) => id !== uid);
    }
    setSelectedEventIds(newSelected);
    warmupLambdas(warmUpUrls);
  };

  const handleAddItemOpen = (type) => {
    setAddItemType(type);
    setAddItemOpen(true);
  };

  const handleAddItemClose = () => {
    setAddItemOpen(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleTournamentChange = (e) => {
    setSelectedTournament(e.target.value);
  };

  // Compute selected event objects and bulk eligibility
  const selectedEventObjects = state.database.events?.filter((event) =>
    selectedEventIds.includes(event.uid)
  );
  const canBulkCreate =
    selectedEventObjects.length > 0 &&
    selectedEventObjects.every(
      (event) =>
        Object.keys(event.config).length === 0 &&
        !isEventPast(event.end_time) &&
        !hasReferenceError(event)
    );
  const canBulkRemove =
    selectedEventObjects.length > 0 &&
    selectedEventObjects.every((event) => Object.keys(event.config).length > 0);
  const canBulkSchedule =
    selectedEventObjects.length > 0 &&
    selectedEventObjects.every(
      (event) =>
        Object.keys(event.config).length > 0 && !isEventPast(event.end_time)
    );
  // New: Bulk Delete is allowed only if for every selected event either there is no config OR the event is past.
  const canBulkDelete =
    selectedEventObjects.length > 0 &&
    selectedEventObjects.every(
      (event) =>
        Object.keys(event.config).length === 0 || isEventPast(event.end_time)
    );

  const handleBulkCreateConfig = async () => {
    const confirmMessage = `Are you sure you want to create config for ${selectedEventObjects.length} event(s)?`;
    try {
      await requestConfirm(confirmMessage);
      const requests = selectedEventObjects.map((event) => {
        const endpoint =
          process.env.REACT_APP_TASKS_URL + "/events/create_event_config";
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ input: { event_uid: event.uid } }),
        };
        return makeApiRequest(endpoint, options, "events").then(
          ({ success, data, error }) => {
            if (success) {
              console.log(`Config created for event ${event.uid}`, data);
            } else {
              console.error(
                `Failed to create config for event ${event.uid}:`,
                error
              );
            }
          }
        );
      });
      await Promise.all(requests);
      onSuccessfulModification && onSuccessfulModification();
      setSelectedEventIds([]);
    } catch (err) {
      console.log("Bulk create config action cancelled.");
    }
  };

  const handleBulkRemoveConfig = async () => {
    const confirmMessage = `Are you sure you want to remove config for ${selectedEventObjects.length} event(s)?`;
    try {
      await requestConfirm(confirmMessage);
      const requests = selectedEventObjects.map((event) => {
        const endpoint =
          process.env.REACT_APP_TASKS_URL + "/events/remove_event_config";
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ input: { event_uid: event.uid } }),
        };
        return makeApiRequest(endpoint, options, "events").then(
          ({ success, data, error }) => {
            if (success) {
              console.log(`Config removed for event ${event.uid}`, data);
            } else {
              console.error(
                `Failed to remove config for event ${event.uid}:`,
                error
              );
            }
          }
        );
      });
      await Promise.all(requests);
      onSuccessfulModification && onSuccessfulModification();
      setSelectedEventIds([]);
    } catch (err) {
      console.log("Bulk remove config action cancelled.");
    }
  };

  const handleBulkSchedule = async () => {
    const confirmMessage = `Are you sure you want to schedule config for ${selectedEventObjects.length} event(s)?`;
    try {
      await requestConfirm(confirmMessage);
      const requests = selectedEventObjects.map((event) => {
        const endpoint =
          process.env.REACT_APP_TASKS_URL + "/events/schedule_event_config";
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ input: { event_uid: event.uid } }),
        };
        return makeApiRequest(endpoint, options, "events").then(
          ({ success, data, error }) => {
            if (success) {
              console.log(`Scheduled config for event ${event.uid}`, data);
            } else {
              console.error(
                `Failed to schedule config for event ${event.uid}:`,
                error
              );
            }
          }
        );
      });
      await Promise.all(requests);
      onSuccessfulModification && onSuccessfulModification();
      setSelectedEventIds([]);
    } catch (err) {
      console.log("Bulk schedule action cancelled.");
    }
  };

  // New: Bulk Delete functionality
  const handleBulkDelete = async () => {
    const confirmMessage = `Are you sure you want to delete ${selectedEventObjects.length} event(s)?`;
    try {
      await requestConfirm(confirmMessage);
      const requests = selectedEventObjects.map((event) => {
        const endpoint = process.env.REACT_APP_DATABASE_URL;
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            command: "command_any_table",
            obj: { command: "delete", item: event, table_name: "events" },
          }),
        };
        return makeApiRequest(endpoint, options, "events").then(
          ({ success, error }) => {
            if (!success) {
              console.error(`Failed to delete event ${event.uid}:`, error);
            }
          }
        );
      });
      await Promise.all(requests);
      onSuccessfulModification && onSuccessfulModification();
      setSelectedEventIds([]);
    } catch (err) {
      console.log("Bulk delete action cancelled.");
    }
  };

  // Define header cells (excluding the new bulk-select column)
  const headCells = [
    { id: "expand", label: "" },
    { id: "uid", label: "UID" },
    { id: "alias", label: "Name" },
    { id: "home_team", label: "Home" },
    { id: "away_team", label: "Away" },
    { id: "start_time", label: "Start" },
    { id: "end_time", label: "End" },
    { id: "number", label: "Number" },
    { id: "stage", label: "Stage" },
    { id: "tournament_uid", label: "Tournament" },
    { id: "workflow_profile_uid", label: "Workflow" },
    { id: "distribution_uid", label: "Distribution" },
    { id: "config built", label: "Config" },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      {ConfirmDialog}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <FormControl
          sx={{
            minWidth: "300px",
            marginLeft: "30px",
            height: "36px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px 5px 0px 0px",
              outline: "none",
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              "& .MuiSelect-select": {
                color: (theme) => theme.palette.primary.contrastText,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: "none",
              borderColor: "transparent",
            },
            "& .MuiSelect-icon": {
              color: (theme) => theme.palette.primary.contrastText,
            },
          }}
        >
          <Select
            labelId="tournament-select-label"
            id="tournament-select"
            value={selectedTournament}
            renderValue={(value) => (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <StadiumIcon />
                <span>
                  {value === "All Tournaments"
                    ? value
                    : state.database.tournaments.find((t) => t.uid === value)
                        ?.alias || value}
                </span>
              </div>
            )}
            onChange={handleTournamentChange}
            style={{
              height: "100%",
              padding: "0 12px",
            }}
          >
            <MenuItem value="All Tournaments">All Tournaments</MenuItem>
            {state.database.tournaments.map((tournament) => (
              <MenuItem key={tournament.uid} value={tournament.uid}>
                {tournament.alias}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          display="flex"
          style={{
            marginLeft: "20px",
            justifyContent: "left",
            alignItems: "center",
            width: "450px",
          }}
        >
          <Button
            variant={eventFilter === "future" ? "contained" : "outlined"}
            color={"primary"}
            startIcon={<UpdateIcon />}
            onClick={() => setEventFilter("future")}
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderRight: 0,
              boxShadow: "0px",
              width: "220px",
            }}
          >
            Upcoming
          </Button>
          <Button
            variant={eventFilter === "today" ? "contained" : "outlined"}
            color={"primary"}
            startIcon={<TodayIcon />}
            onClick={() => setEventFilter("today")}
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              boxShadow: "0px",
              width: "180px",
            }}
          >
            Today
          </Button>
          <Button
            variant={eventFilter === "past" ? "contained" : "outlined"}
            color={"primary"}
            startIcon={<HistoryIcon />}
            onClick={() => setEventFilter("past")}
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderLeft: 0,
              borderRight: 0,
              boxShadow: "0px",
              width: "180px",
            }}
          >
            Past
          </Button>
          <Button
            variant={eventFilter === "all" ? "contained" : "outlined"}
            color={"primary"}
            onClick={() => setEventFilter("all")}
            startIcon={<DensityMediumIcon />}
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              boxShadow: "0px",
              width: "180px",
            }}
          >
            All
          </Button>
        </Box>

        <Button
          onClick={() => handleAddItemOpen("event")}
          color="secondary"
          variant="contained"
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            minWidth: "250px",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
            marginLeft: "auto",
            marginRight: "250px",
          }}
        >
          <SportsIcon style={{ marginRight: "5px" }} /> Add Event
        </Button>
        {/* <Button
          onClick={() => handleAddItemOpen("tournament")}
          variant="contained"
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            minWidth: "250px",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
          }}
        >
          <StadiumIcon style={{ marginRight: "5px" }} /> Add Tournament
        </Button> */}
        {/* <Button
          onClick={() => {}}
          disabled={true}
          variant="contained"
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            minWidth: "250px",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
            marginRight: "50px",
          }}
        >
          <AutoFixHighIcon style={{ marginRight: "5px" }} /> Workflow Wizard
        </Button> */}
      </div>

      {/* Bulk Action Buttons */}
      <AddItemFormRouting
        open={addItemOpen}
        onClose={handleAddItemClose}
        schema={
          addItemType === "event"
            ? currentSchema
            : schemas.tables["tournaments"].item_schema
        }
        tableName={addItemType === "event" ? "events" : "tournaments"}
      />
      <TableContainer component={Paper}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEvents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* Bulk Actions */}
        <Box display="flex" alignItems="center" gap={2} ml={4}>
          <Button
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              marginLeft: "20px",
              minWidth: "190px",
            }}
            variant="contained"
            color="primary"
            onClick={handleBulkCreateConfig}
            disabled={!canBulkCreate}
          >
            <AddCircleOutlineIcon style={{ marginRight: "5px" }} />
            Create Config
          </Button>
          <Button
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              marginLeft: "20px",
              minWidth: "190px",
            }}
            variant="contained"
            color="secondary"
            onClick={handleBulkRemoveConfig}
            disabled={!canBulkRemove}
          >
            <RemoveCircleOutlineIcon style={{ marginRight: "5px" }} />
            Remove Config
          </Button>
          <Button
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              marginLeft: "20px",
              minWidth: "190px",
            }}
            variant="contained"
            color="primary"
            onClick={handleBulkSchedule}
            disabled={!canBulkSchedule}
          >
            <CalendarMonthIcon style={{ marginRight: "5px" }} />
            Schedule Rundown
          </Button>
          <Button
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              marginLeft: "20px",
              minWidth: "190px",
            }}
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
            disabled={!canBulkDelete}
          >
            <DeleteIcon style={{ marginRight: "5px" }} />
            Delete Events
          </Button>
        </Box>
        <Table>
          <TableHead style={{ borderBottom: "1px solid grey" }}>
            <TableRow>
              {/* Bulk select header cell */}
              <TableCell padding="checkbox" style={{ width: "70px" }}>
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"left"}
                >
                  <Checkbox
                    indeterminate={
                      selectedEventIds.length > 0 &&
                      selectedEventIds.length < filteredEvents.length
                    }
                    checked={
                      filteredEvents.length > 0 &&
                      selectedEventIds.length === filteredEvents.length
                    }
                    onChange={handleSelectAllClick}
                  />
                  <Typography>
                    {JSON.stringify(selectedEventIds.length)}
                  </Typography>
                </Box>
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? orderDirection : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell key={"eventDetails"}>
                <Typography>Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedEvents.map((event, index) => (
              <EventRow
                index={index}
                event={event}
                key={event.uid}
                isPast={isEventPast(event.end_time)}
                selected={selectedEventIds.includes(event.uid)}
                onSelect={() => handleRowClick(event.uid)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
