// EventRow.jsx
import React, { useContext, useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Box,
  Button,
  Chip,
  Collapse,
  Checkbox,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/QuestionMark";
import PanoramaWideAngleSelectIcon from "@mui/icons-material/PanoramaWideAngleSelect";
import moment from "moment-timezone";
import { useTheme } from "@mui/material/styles";
import { MyContext } from "../../context/Context";
import { useApiRequest } from "../Utility/useAPIRequest";
import useConfirm from "../Utility/useConfirm";
import JSONDialog from "../Utility/JSONDialog";
import EventTimeUpdater from "./EventTimeUpdater";
import MixConfigs from "./MixConfigs";
import schemas from "../Database/schemas.json";
import PanoramaWideAngleIcon from "@mui/icons-material/PanoramaWideAngle";
import { useNavigate } from "react-router-dom";
import RecordConfigs from "./RecordConfigs";
import ExchangeConfigs from "./ExchangeConfigs";
import MonitorConfigs from "./MonitorConfigs";
export default function EventRow({ event, isPast, selected, onSelect, index }) {
  const { state } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [refError, setRefError] = useState(false);
  const { makeApiRequest } = useApiRequest();
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const [isPickerOpen, setPickerOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isOdd = index % 2 !== 0;
  const rowStyle = isPast
    ? {
        backgroundColor: theme.palette.mode === "dark" ? "#2e2e2e" : "#f0f0f0",
      }
    : {
        backgroundColor: isOdd
          ? theme.custom.rowColors.even
          : theme.custom.rowColors.odd,
      };

  useEffect(() => {
    const checkForRefErrors = () => {
      const itemSchema = schemas.tables.events.item_schema;
      for (const key in itemSchema) {
        if (itemSchema[key].tableReference) {
          const referencedTable = itemSchema[key].tableReference;
          const refExists = state.database[referencedTable]?.some(
            (item) => item.uid === event[key]
          );
          if (!refExists) {
            setRefError(true);
            return;
          }
        }
      }
      setRefError(false);
    };
    checkForRefErrors();
  }, [event, state.database]);

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
  };

  const handleSubmitTimes = (times) => {
    const updatedEvent = {
      ...event,
      start_time: times.startTime,
      end_time: times.endTime,
    };
    handleUpdateTime(updatedEvent);
    setPickerOpen(false);
  };

  const handleDeleteEvent = async (item) => {
    try {
      await requestConfirm("Are you sure you want to delete this event? ");
      let tableName = "events";
      const endpoint = process.env.REACT_APP_DATABASE_URL;
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          command: "command_any_table",
          obj: { command: "delete", item, table_name: tableName },
        }),
      };
      const { success, error } = await makeApiRequest(
        endpoint,
        options,
        tableName
      );
      if (!success) console.error("Failed to delete item:", error);
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const handleUpdateTime = async (updatedItem) => {
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        command: "command_any_table",
        obj: { command: "put", item: updatedItem, table_name: "events" },
      }),
    };
    const { success, data, error } = await makeApiRequest(
      endpoint,
      options,
      "events"
    );
    if (success) {
      console.log(data);
    } else {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleConfigure = async () => {
    try {
      if (Object.keys(event.config).length > 0) {
        await requestConfirm(
          "Are you sure you want to configure this event? CAUTION: The existing config for this event will be overwritten on the associated platform!"
        );
      } else {
        await requestConfirm("Are you sure you want to configure this event?");
      }
      const endpoint =
        process.env.REACT_APP_TASKS_URL + "/events/create_event_config";
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ input: { event_uid: event.uid } }),
      };
      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const handleRemoveConfigure = async () => {
    try {
      if (Object.keys(event.config).length > 0) {
        await requestConfirm(
          "Are you sure you want remove config of this event? CAUTION: The existing resources for this event will be removed."
        );
      } else {
        await requestConfirm("Are you sure you want to remove the config?");
      }
      const endpoint =
        process.env.REACT_APP_TASKS_URL + "/events/remove_event_config";
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ input: { event_uid: event.uid } }),
      };
      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const handleSchedule = async () => {
    try {
      await requestConfirm(
        "Are you sure you want to schedule this event configuration?"
      );
      const endpoint =
        process.env.REACT_APP_TASKS_URL + "/events/schedule_event_config";
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ input: { event_uid: event.uid } }),
      };
      const { success, data, error } = await makeApiRequest(
        endpoint,
        options,
        "events"
      );
      if (success) {
        console.log(data);
      } else {
        console.error("Failed to fetch data:", error);
      }
    } catch (error) {
      console.log("Action cancelled by user");
    }
  };

  const formatValue = (key, value) => {
    let tableName = "events";
    if (schemas.tables[tableName]?.item_schema[key]?.format === "timestamp") {
      const formattedTime = moment
        .tz(Number(value), state.settings.timezone)
        .format("MM/DD/YYYY HH:mm");
      return <div style={{ fontSize: "15px" }}>{formattedTime}</div>;
    }
    if (schemas.tables[tableName]?.item_schema[key]?.tableReference) {
      const referencedTable =
        schemas.tables[tableName].item_schema[key].tableReference;
      const referencedItem = state.database[referencedTable]?.find(
        (item) => item.uid === value
      );
      if (referencedItem) {
        return (
          <>
            {key === "distribution_uid" && referencedItem.distribution ? (
              <Chip
                style={{ width: "45px" }}
                color="primary"
                variant="contained"
                label={
                  <Typography style={{ fontWeight: "bold" }}>
                    {referencedItem.distribution}
                  </Typography>
                }
                size="medium"
              />
            ) : (
              <div style={{ fontSize: "15px" }}>
                {referencedItem.alias.replace(/_/g, " ")}
              </div>
            )}
          </>
        );
      } else {
        return (
          <div
            style={{ fontSize: "13px", color: theme.palette.secondary.main }}
          >
            <p>Reference Error</p>
            <p
              style={{ fontSize: "10px", color: theme.palette.secondary.main }}
            >
              {value}
            </p>
          </div>
        );
      }
    }
    return value;
  };

  const homeTeam = state.database.tournaments
    ?.find((profile) => profile.uid === event.tournament_uid)
    ?.teams.find((team) => team.uid === event.home_team);

  const awayTeam = state.database.tournaments
    ?.find((profile) => profile.uid === event.tournament_uid)
    ?.teams.find((team) => team.uid === event.away_team);

  return (
    <>
      {ConfirmDialog}
      <TableRow key={event.uid} style={rowStyle}>
        {/* Selection checkbox */}
        <TableCell padding="checkbox" style={{ width: "70px" }}>
          {" "}
          <Checkbox checked={selected} onChange={onSelect} />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ maxWidth: "160px", wordWrap: "break-word" }}>
          <div style={{ fontSize: "10px", color: "grey" }}>{event.uid}</div>
        </TableCell>
        <TableCell>{event.alias.replace(/_/g, " ")}</TableCell>
        <TableCell>
          <Box display="flex" alignItems={"center"} gap={"5px"}>
            {homeTeam.logo_url === "" ? (
              <PanoramaWideAngleIcon />
            ) : (
              <img
                src={homeTeam.logo_url}
                style={{ maxWidth: "20px" }}
                alt="Home"
              />
            )}
            <Typography>{homeTeam.alias}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems={"center"} gap={"5px"}>
            {awayTeam.logo_url === "" ? (
              <PanoramaWideAngleIcon />
            ) : (
              <img
                src={awayTeam.logo_url}
                style={{ maxWidth: "20px" }}
                alt="Away"
              />
            )}
            <Typography>{awayTeam.alias}</Typography>
          </Box>
        </TableCell>
        <TableCell>{formatValue("start_time", event.start_time)}</TableCell>
        <TableCell>{formatValue("end_time", event.end_time)}</TableCell>
        <TableCell>{event.number}</TableCell>
        <TableCell>{event.stage.replace(/_/g, " ")}</TableCell>
        <TableCell>
          {formatValue("tournament_uid", event.tournament_uid)}
        </TableCell>
        <TableCell>
          {formatValue("workflow_profile_uid", event.workflow_profile_uid)}
        </TableCell>
        <TableCell>
          {formatValue("distribution_uid", event.distribution_uid)}
        </TableCell>
        <TableCell>
          {Object.keys(event.config).length > 0 ? (
            <Box display="flex" justifyContent={"left"} gap={"5px"}>
              <JSONDialog input_json={event.config} name={event.alias} />
            </Box>
          ) : (
            <Typography style={{ color: theme.palette.secondary.main }}>
              <Box>
                <Button
                  disabled
                  variant="outlined"
                  sx={{
                    "&.Mui-disabled": {
                      color: "primary.main",
                      borderColor: "primary.main",
                    },
                  }}
                >
                  <HelpOutlineIcon />
                </Button>
              </Box>
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Tooltip title="Open Event Details" arrow>
            <Button
              onClick={() => navigate(`/events/${event.uid}`)}
              variant="outlined"
              color="primary"
            >
              <DisplaySettingsIcon />
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={14} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display={"flex"} gap={"30px"}>
              <Button
                onClick={handleConfigure}
                variant="contained"
                color="primary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "10vw",
                  minWidth: "150px",
                }}
                disabled={
                  Object.keys(event.config).length > 0 || isPast || refError
                }
              >
                <AddCircleOutlineIcon style={{ marginRight: "5px" }} />
                Create config
              </Button>
              <Button
                onClick={handleRemoveConfigure}
                disabled={!(Object.keys(event.config).length > 0)}
                variant="contained"
                color="secondary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <RemoveCircleOutlineIcon style={{ marginRight: "5px" }} />
                Remove Config
              </Button>
              <Button
                onClick={handleSchedule}
                disabled={!(Object.keys(event.config).length > 0) || isPast}
                variant="contained"
                color="primary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <CalendarMonthIcon style={{ marginRight: "5px" }} />
                Schedule Rundown
              </Button>
              <Button
                onClick={handleOpenPicker}
                variant="contained"
                color="secondary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
                disabled={Object.keys(event.config).length > 0}
              >
                <QueryBuilderIcon style={{ marginRight: "5px" }} />
                Update Time
              </Button>
              {isPickerOpen && (
                <EventTimeUpdater
                  onSubmit={handleSubmitTimes}
                  onCancel={handleClosePicker}
                />
              )}
              <Button
                onClick={() => navigate(`/events/${event.uid}`)}
                variant="contained"
                color="primary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <DisplaySettingsIcon style={{ marginRight: "5px" }} />
                Details
              </Button>
              <Button
                onClick={() => {
                  handleDeleteEvent(event);
                }}
                disabled={Object.keys(event.config).length > 0 && !isPast}
                variant="contained"
                color="secondary"
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  marginLeft: "30px",
                  minWidth: "150px",
                }}
              >
                <DeleteIcon style={{ marginRight: "5px" }} />
                Delete Event
              </Button>
            </Box>
            <Box style={{ margin: "30px" }}>
              <Box display="flex" justifyContent={"left"}>
                <ExchangeConfigs
                  config={event.config?.exchange_configs || []}
                  eventUid={event.uid}
                ></ExchangeConfigs>{" "}
                <MixConfigs
                  config={event.config?.mix_configs || []}
                  eventUid={event.uid}
                />
                <RecordConfigs
                  config={event.config?.record_configs || []}
                  eventUid={event.uid}
                ></RecordConfigs>
                <MonitorConfigs
                  config={event.config?.monitoring_configs || []}
                  eventUid={event.uid}
                ></MonitorConfigs>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
