const initialState = {
  activeRequests: 0,
  loading: false,
  database: {},
  value: 0,
  settings: {
    timezone: "...",
    darkMode: false,
  },
  status: {},
};

function reducer(state, action) {
  switch (action.type) {
    case "INCREMENT":
      return { ...state, value: state.value + 1 };
    case "DECREMENT":
      return { ...state, value: state.value - 1 };
    case "SET_DATABASE_DATA":
      return { ...state, database: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "INCREMENT_LOADING":
      return {
        ...state,
        activeRequests: state.activeRequests + 1,
        loading: true,
      };
    case "DECREMENT_LOADING":
      const activeRequests = Math.max(state.activeRequests - 1, 0);
      return {
        ...state,
        activeRequests,
        loading: activeRequests > 0,
      };
    case "SET_STATUS_DATA":
      return { ...state, status: action.payload };
    case "SET_SCHEDULES":
      return {
        ...state,
        status: { ...state.status, schedules: action.payload },
      };
    case "SET_SETTINGS":
      return {
        ...state,
        settings: action.payload,
      };
    case "SET_TIMEZONE":
      return {
        ...state,
        settings: { ...state.settings, timezone: action.payload },
      };
    case "SET_DARKMODE":
      return {
        ...state,
        settings: { ...state.settings, darkMode: action.payload },
      };
    default:
      return state;
  }
}

export { reducer, initialState };
