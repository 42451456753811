import React, { useState, useContext, useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";

export default function ElixirService({ service, region }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleRestart = () => {
    // Implement the logic to restart the service
    console.log(`Restarting service: ${service.serviceName}`);
  };

  const handleDelete = async (service_name) => {
    try {
      await requestConfirm(
        "Are you sure you want delete this container? " + service_name
      );
      let url =
        process.env.REACT_APP_TASKS_URL +
        "/components/mix/elixir/delete_elixir_service";
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: { service_name: service_name },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  return (
    <div>
      {ConfirmDialog}
      <Card variant="outlined" sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            {service.serviceName}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            Status: {service.status}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            Region: {region}
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleRestart}
            sx={{ marginRight: 1 }}
          >
            Restart
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDelete(service.serviceName);
            }}
          >
            Delete
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
