import React, { useContext, useState } from "react";
import { MyContext } from "../../context/Context";
import ScheduleTable from "./ScheduleTable";
import LoaderSkeleton from "../Utility/LoaderSkeleton";
import { Box, Button, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArchiveIcon from "@mui/icons-material/Archive";
import ArchivedScheduleTable from "./ArchivedScheduleTable";
export default function Schedules() {
  const { state } = useContext(MyContext);
  const [view, setView] = useState("schedules"); // "schedules" or "archived"

  const handleViewChange = (newView) => {
    setView(newView);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
      }}
    >
      {/* Buttons to toggle between views */}
      {state.status.schedules ? (
        <Box sx={{ display: "flex" }}>
          <Button
            variant={view === "schedules" ? "contained" : "outlined"}
            onClick={() => handleViewChange("schedules")}
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              marginLeft: "20px",
              minWidth: "150px",
              boxShadow:
                view === "schedules"
                  ? "0px -4px 8px rgba(0, 0, 0, 0.0)"
                  : "px 0px 0px rgba(0, 0, 0, 0)",
            }}
          >
            <CalendarMonthIcon style={{ marginRight: "5px" }} />
            Schedules
          </Button>
          <Button
            variant={view === "archived" ? "contained" : "outlined"}
            onClick={() => handleViewChange("archived")}
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              marginLeft: "20px",
              minWidth: "150px",
              boxShadow:
                view === "archived"
                  ? "0px -4px 8px rgba(0, 0, 0, 0.0)"
                  : "px 0px 0px rgba(0, 0, 0, 0)",
            }}
          >
            {" "}
            <ArchiveIcon style={{ marginRight: "5px" }} />
            Archive
          </Button>
        </Box>
      ) : null}

      {/* Conditional rendering based on selected view */}
      {view === "schedules" && (
        <Box>
          {state.status.schedules ? (
            <ScheduleTable
              schedules={state.status.schedules}
              timezone={state.settings.timezone}
            />
          ) : (
            <LoaderSkeleton skeletonCount={5} />
          )}
        </Box>
      )}

      {view === "archived" && (
        <Box>
          {state.database.schedules_archive ? (
            <ArchivedScheduleTable
              schedules={state.database.schedules_archive}
              timezone={state.settings.timezone}
            />
          ) : (
            <Typography variant="body1" align="center">
              No archived schedules available
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
