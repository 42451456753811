import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import dayjs from "dayjs";
import FilterIcon from "@mui/icons-material/Filter";
import PublicIcon from "@mui/icons-material/Public";
import CastIcon from "@mui/icons-material/Cast";

function MixMediaLiveDetails({ mixConfig, mixIndex, eventUid }) {
  return (
    <Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <p>
          <strong>{mixConfig.profile.alias}</strong>
        </p>
      </Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <CastIcon></CastIcon>
        <p>
          Destination: <strong>{mixConfig.route_name.toUpperCase()} </strong>
        </p>
      </Box>
      <Box display="flex" alignItems="center" gap="10px" height="30px">
        <PublicIcon />

        <p>
          Region: <strong>{mixConfig.profile.region}</strong>
        </p>
      </Box>
      {Array.isArray(mixConfig.profile.graphics) &&
        mixConfig.profile.graphics.map((graphic, graphicIndex) => (
          <Box
            key={graphicIndex}
            display="flex"
            gap="10px"
            alignItems="center"
            height="30px"
          >
            {/* Clickable FilterIcon that opens the URL in a new tab */}
            <Box
              onClick={() =>
                window.open(graphic.url, "_blank", "noopener,noreferrer")
              }
              sx={{ cursor: "pointer" }} // Add pointer cursor on hover
            >
              <FilterIcon />
            </Box>

            <p>
              Graphics: <strong>{graphic.alias}</strong>
            </p>
          </Box>
        ))}
    </Box>
  );
}

export default MixMediaLiveDetails;
