import React, { useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import VideoElement from "./VideoElement";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

export default function PlayerCollection({
  selectedEventUids,
  events,
  workflowProfiles,
  distributions,
  ovenHosts,
  showBackup,
  showControls,
  isExpanded,
}) {
  const playerContainerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      playerContainerRef.current?.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const getHostForDistribution = (distributionUid) => {
    const distribution = distributions.find((d) => d.uid === distributionUid);
    const distributionAlias = distribution?.distribution || "default";

    const oven = ovenHosts.find((oven) =>
      oven.distributions.includes(distributionAlias)
    );

    return oven?.host;
  };

  const getRoutesForEvent = (event) => {
    return (
      event.config?.exchange_configs?.flatMap((exchangeConfig) =>
        exchangeConfig.api_config?.routes.flatMap((route) => {
          const routes = [
            {
              routeName: `${route.route_name}_main`,
              routeUid: `${route.route_uid}_main`,
              host: getHostForDistribution(event.distribution_uid),
              label: "Main",
              event,
            },
          ];

          if (showBackup) {
            routes.push({
              routeName: `${route.route_name}_backup`,
              routeUid: `${route.route_uid}_backup`,
              host: getHostForDistribution(event.distribution_uid),
              label: "Backup",
              event,
            });
          }

          return routes;
        })
      ) || []
    );
  };

  const allRoutes = [];

  selectedEventUids.forEach((eventUid) => {
    const event = events.find((e) => e.uid === eventUid);
    if (!event) return;

    const routes = getRoutesForEvent(event);
    routes.forEach((route) => {
      if (
        !allRoutes.some(
          (existingRoute) =>
            existingRoute.routeUid === route.routeUid &&
            route.event.distribution_uid ===
              existingRoute.event.distribution_uid
        )
      ) {
        allRoutes.push(route);
      }
    });
  });

  const elementsPerRow = 5;
  const totalElements = allRoutes.length;
  const missingElements = elementsPerRow - (totalElements % elementsPerRow);
  const placeholders = missingElements < elementsPerRow ? missingElements : 0;

  return (
    <div>
      <Box
        ref={playerContainerRef}
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "2px",
            width: "100%",
          }}
        >
          {allRoutes.map((route) => (
            <VideoElement
              key={route.routeUid + route.event.uid}
              host={route.host}
              port="3334"
              streamName={`/app/${route.routeName}`}
              playerId={`player_${route.event.uid}_${route.routeUid}`}
              label={`${route.routeName} (${route.label})`}
              event={route.event}
              showControls={showControls}
            />
          ))}
          {Array.from({ length: placeholders }).map((_, index) => (
            <Box
              key={`placeholder_${index}`}
              sx={{
                minWidth: "19.9%",
                height: "0",
                paddingBottom: "calc(19.5% * 9 / 16)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            />
          ))}
        </Box>
        {/* Fullscreen Toggle Button */}
        {isExpanded ? (
          <Button
            onClick={toggleFullscreen}
            sx={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              color: isFullscreen ? "white" : "black",
            }}
          >
            {isFullscreen ? (
              <FullscreenExitIcon />
            ) : (
              <Box display="flex" alignItems={"center"} gap="10px">
                <Typography>Open Fullscreen</Typography>
                <FullscreenIcon style={{ marginBottom: "2px" }} />
              </Box>
            )}
          </Button>
        ) : null}
      </Box>
    </div>
  );
}
