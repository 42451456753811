import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { MyContext } from "../../../context/Context";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import JsonImporter from "../../Utility/JsonImporter";
const AddTvuProfileForm = ({ open, onClose, onAdd, schema }) => {
  const { state } = useContext(MyContext);
  const [languageInput, setLanguageInput] = useState("");
  const [selectedGraphicsProfile, setSelectedGraphicsProfile] = useState("");
  const [selectedGraphicsTypeProfile, setSelectedGraphicsTypeProfile] =
    useState("");
  const [selectedAbility, setSelectedAbility] = useState("");

  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    uid: uuidv4(),
    alias: "",
    email: "",
    format: "",
    graphics: [],
    languages: [],
    output_bitrate: "",
    output_resolution: "",
    region: "",
    rundown: [],
    start_offset: "",
    stop_offset: "",
    cookies: "",
    profile: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddGraphicsProfile = (graphics_profile) => {
    setSelectedGraphicsProfile(graphics_profile);
    setFormData({
      ...formData,
      graphics:
        Array.isArray(graphics_profile) && graphics_profile.length === 0
          ? [] // If None is selected, keep graphics as an empty array.
          : [graphics_profile],
    });
  };

  const handleJsonImport = (importedJson) => {
    setFormData((prev) => ({
      ...prev,
      ...importedJson,
    }));
  };

  const handleAddLanguage = (language) => {
    if (language && !formData.languages.includes(language)) {
      setFormData({
        ...formData,
        languages: [...formData.languages, language],
      });
    }
  };

  // Function to remove a language from the languages array
  const handleRemoveLanguage = (languageToRemove) => {
    setFormData({
      ...formData,
      languages: formData.languages.filter(
        (language) => language !== languageToRemove
      ),
    });
  };

  const handleAddRundownTask = (taskToAdd) => {
    // Check if the rundown already contains a task with the same uid
    const isAlreadyAdded = formData.rundown.some(
      (task) => task.uid === taskToAdd.uid
    );

    // If not already added, append the new task to the rundown array
    if (!isAlreadyAdded) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        rundown: [...prevFormData.rundown, taskToAdd],
      }));
    }
  };

  const handleClose = () => {
    setFormData((prev) => ({
      ...prev,
      uid: uuidv4(),
    }));
    onClose();
  };
  const handleSubmit = () => {
    const isValid = Object.keys(schema).every((key) => {
      if (schema[key].required && formData[key] === "") {
        setError(`Field "${key}" is required.`);
        return false;
      }
      return true;
    });

    if (isValid) {
      onClose();
      setError("");
      onAdd(formData);
      setFormData({
        uid: uuidv4(),
        alias: "",
        email: "",
        format: "",
        graphics: [],
        languages: [],
        output_bitrate: "",
        output_resolution: "",
        region: "",
        rundown: [],
        start_offset: "",
        stop_offset: "",
        cookies: "",
        profile: "",
      });

      console.log(formData);
    }
  };
  const handleRemoveRundownTask = (task_to_remove) => {
    setFormData({
      ...formData,
      rundown: formData.rundown.filter((task) => task !== task_to_remove),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {" "}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"space-between"}
          style={{ borderBottom: "1px solid grey", paddingBottom: "15px" }}
        >
          <Box display="flex" gap="10px">
            <Typography variant="body1">Add New TVU Profile Item</Typography>
          </Box>
          <JsonImporter onImport={handleJsonImport} />
        </Box>
      </DialogTitle>{" "}
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          autoFocus
          margin="dense"
          name="uid"
          label="UID"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.uid}
          onChange={handleChange}
        />
        <TextField
          autoFocus
          margin="dense"
          name="alias"
          label="Alias"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.alias}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="format"
          label="Format"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.format}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="cookies"
          label="Cookies"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.cookies}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="output_bitrate"
          label="Output Bitrate (kbps)"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.output_bitrate}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="output_resolution"
          label="Output Resolution"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.output_resolution}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="profile"
          label="H264 profile (high, main, high422, baseline)"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.profile}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="region"
          label="Region"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.region}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="start_offset"
          label="Start Offset"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.start_offset}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="stop_offset"
          label="Stop Offset"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.stop_offset}
          onChange={handleChange}
        />{" "}
        <TextField
          margin="dense"
          name="language"
          label="Language"
          type="text"
          fullWidth
          variant="outlined"
          value={languageInput}
          onChange={(e) => setLanguageInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && languageInput) {
              e.preventDefault();
              handleAddLanguage(languageInput);
              setLanguageInput("");
            }
          }}
        />
        <Button
          onClick={() => {
            if (languageInput) {
              handleAddLanguage(languageInput);
              setLanguageInput("");
            }
          }}
          style={{ marginTop: "10px" }}
          variant="contained"
        >
          Add Language
        </Button>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.5,
            marginTop: "10px",
          }}
        >
          {formData.languages.map((language, index) => (
            <Chip
              key={index}
              label={language}
              onDelete={() => handleRemoveLanguage(language)}
              color="primary"
            />
          ))}
        </Box>
        <FormControl fullWidth margin="dense">
          <InputLabel id="graphics-profile-select-label">
            Graphics Profile
          </InputLabel>
          <Select
            labelId="graphics-profile-select-label"
            value={selectedGraphicsProfile}
            onChange={(e) => handleAddGraphicsProfile(e.target.value)}
            label="Graphics Profile"
          >
            <MenuItem key={"default_value"} value={[]}>
              None
            </MenuItem>
            {state.database.graphics_profiles.map((profile) => (
              <MenuItem key={profile.uid} value={profile}>
                {profile.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormControl fullWidth margin="dense">
            <InputLabel id="tvu-abilities-select-label">
              TVU Rundown Tasks{" "}
            </InputLabel>
            <Select
              labelId="tvu-abilities-select-label"
              value={selectedAbility}
              onChange={(e) => setSelectedAbility(e.target.value)}
              label="TVU Rundwon Tasks"
            >
              {state.database.tvu_abilities.map((ability) => (
                <MenuItem key={ability.uid} value={ability}>
                  {ability.alias}
                </MenuItem>
              ))}
            </Select>
            {selectedAbility ? (
              <TextField
                autoFocus
                margin="dense"
                name="alias"
                label="execution_offset"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedAbility.execution_offset}
                onChange={(e) => {
                  setSelectedAbility({
                    ...selectedAbility,
                    execution_offset: e.target.value,
                  });
                }}
              />
            ) : null}
            <Button
              variant="contained"
              onClick={() => {
                handleAddRundownTask(selectedAbility);
              }}
              style={{ marginTop: "10px" }}
            >
              Add Rundown Task
            </Button>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                marginTop: "10px",
              }}
            >
              {formData.rundown.map((ability, index) => (
                <Chip
                  key={ability.uid + index}
                  label={ability.alias + " : " + ability.execution_offset}
                  onDelete={() => {
                    handleRemoveRundownTask(ability);
                  }}
                  color="primary"
                />
              ))}
            </Box>
          </FormControl>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTvuProfileForm;
