import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Card,
  Paper,
  Button,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  IconButton,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Checkbox,
  TextField,
} from "@mui/material";
import ScheduleRow from "./ScheduleRow"; // Adjust the import path as needed
import useCronTranslator from "../Utility/useCronTranslator";
import DataObjectIcon from "@mui/icons-material/DataObject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FunctionsIcon from "@mui/icons-material/Functions";
import Description from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Nirvana from "../Utility/Nirvana";
import moment from "moment-timezone";
import CustomDateTimePicker from "../Utility/CustomDateTimePicker";
import useUnixMsToDate from "../Utility/useUnixMsToDate";
import BoltIcon from "@mui/icons-material/Bolt";
import PublishIcon from "@mui/icons-material/Publish";
import { jsx } from "@emotion/react";

function ScheduleTable({ schedules, timezone }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("ScheduleExpression");

  const translateCronExpression = useCronTranslator(timezone);
  const translateUnixExpression = useUnixMsToDate();

  // General Filter State
  const [filter, setFilter] = useState("");

  // Individual field filters
  const [eventFilter, setEventFilter] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [taskFilter, setTaskFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  // Time Filter (for filtering rows by time)
  const [timeFilter, setTimeFilter] = useState("");

  // Dropdown values for filters
  const [events, setEvents] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [types, setTypes] = useState([]);

  // New state for bulk selection and bulk update
  const [selected, setSelected] = useState([]);
  const [bulkNewTime, setBulkNewTime] = useState(null); // Holds the date/time from picker
  const [bulkNewTimezone, setBulkNewTimezone] = useState(null);

  // Extract distinct values for dropdowns
  useEffect(() => {
    if (schedules && schedules.length > 0) {
      const eventSet = new Set();
      const numberSet = new Set();
      const taskSet = new Set();
      const typeSet = new Set();

      schedules.forEach((schedule) => {
        const [event, number, task, type] = schedule.Description.split(";");
        eventSet.add(event);
        numberSet.add(number);
        taskSet.add(task);
        typeSet.add(type);
      });

      setEvents([...eventSet]);
      setNumbers([...numberSet]);
      setTasks([...taskSet]);
      setTypes([...typeSet]);
    }
  }, [schedules]);

  // Reset selected items whenever any filter changes.
  useEffect(() => {
    setSelected([]);
  }, [filter, eventFilter, numberFilter, taskFilter, typeFilter, timeFilter]);

  // Helper to convert a given time to a cron expression
  const convertToCronExpression = (dateTime, tz) => {
    const utcDateTime = moment.tz(dateTime, tz).utc();
    return `cron(${utcDateTime.format("m")} ${utcDateTime.format(
      "H"
    )} ${utcDateTime.format("D")} ${utcDateTime.format(
      "M"
    )} ? ${utcDateTime.format("YYYY")})`;
  };

  // Apply the filter based on schedule description and sorting
  const sortedData = schedules
    ? [...schedules]
        .filter((schedule) => {
          const description = schedule.Description || "";
          const [event, number, task, type] = description.split(";");
          const scheduleTime = translateCronExpression(
            schedule.ScheduleExpression
          );

          const passesGeneralFilter = description
            .toLowerCase()
            .includes(filter.toLowerCase());
          const passesEventFilter = eventFilter
            ? event.toLowerCase() === eventFilter.toLowerCase()
            : true;
          const passesNumberFilter = numberFilter
            ? number.toLowerCase() === numberFilter.toLowerCase()
            : true;
          const passesTaskFilter = taskFilter
            ? task.toLowerCase() === taskFilter.toLowerCase()
            : true;
          const passesTypeFilter = typeFilter
            ? type.toLowerCase() === typeFilter.toLowerCase()
            : true;
          const passesTimeFilter = timeFilter
            ? scheduleTime.includes(timeFilter)
            : true;

          return (
            passesGeneralFilter &&
            passesEventFilter &&
            passesNumberFilter &&
            passesTaskFilter &&
            passesTypeFilter &&
            passesTimeFilter
          );
        })
        .sort((a, b) => {
          const valueA =
            orderBy === "ScheduleExpression"
              ? translateCronExpression(a[orderBy])
              : a[orderBy] || "";
          const valueB =
            orderBy === "ScheduleExpression"
              ? translateCronExpression(b[orderBy])
              : b[orderBy] || "";
          return (
            (orderDirection === "asc" ? 1 : -1) *
            (valueA < valueB ? -1 : valueA > valueB ? 1 : 0)
          );
        })
    : [];

  // Paginate the sorted data
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handlers for selection checkboxes
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelected(sortedData.map((schedule) => schedule.Name));
    } else {
      setSelected([]);
    }
  };

  const handleRowSelect = (scheduleName, checked) => {
    if (checked) {
      setSelected((prev) => [...prev, scheduleName]);
    } else {
      setSelected((prev) => prev.filter((name) => name !== scheduleName));
    }
  };

  // Bulk Run: Update selected schedules to run 2 minutes from now.
  const handleBulkRun = async () => {
    if (selected.length === 0) return;
    try {
      await requestConfirm(
        "Are you sure you want to run the selected schedules (set to run in 2 minutes)?"
      );
      const runTime = moment()
        .utc()
        .add(2, "minutes")
        .format("MM/DD/YYYY HH:mm");
      const cron_expression = convertToCronExpression(runTime, timezone);

      const schedule_updates = sortedData
        .filter((schedule) => selected.includes(schedule.Name))
        .map((schedule) => ({
          schedule_name: schedule.Name,
          cron_expression,
          description: schedule.Description,
          group_name: schedule.GroupName,
        }));
      const url = process.env.REACT_APP_UPDATE_SCHEDULE_URL; // Assuming same update API
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ schedule_updates }),
        },
        onSuccessfulModification
      );
      if (result.success) {
        setSelected([]);
      }
    } catch (error) {
      console.log("Bulk run action cancelled by user", error);
    }
  };

  // Bulk Update Time: Update selected schedules with the chosen new time.
  const handleBulkUpdateTime = async () => {
    if (selected.length === 0) return;
    if (!bulkNewTime) {
      alert("Please select a new time for bulk update.");
      return;
    }
    try {
      await requestConfirm(
        "Are you sure you want to update the time for the selected schedules?"
      );
      const tz = bulkNewTimezone || timezone;
      const cron_expression = convertToCronExpression(bulkNewTime, tz);
      const schedule_updates = sortedData
        .filter((schedule) => selected.includes(schedule.Name))
        .map((schedule) => ({
          schedule_name: schedule.Name,
          cron_expression,
          description: schedule.Description,
          group_name: schedule.GroupName,
        }));
      const url = process.env.REACT_APP_UPDATE_SCHEDULE_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ schedule_updates }),
        },
        onSuccessfulModification
      );
      if (result.success) {
        setSelected([]);
      }
    } catch (error) {
      console.log("Bulk update action cancelled by user", error);
    }
  };

  // Delete Selected: Delete only the schedules that are checked.
  const handleDeleteSelected = async () => {
    if (selected.length === 0) return;
    try {
      await requestConfirm(
        "Are you sure you want to delete the selected schedules?"
      );
      const schedulesToDelete = sortedData
        .filter((schedule) => selected.includes(schedule.Name))
        .map((schedule) => ({
          schedule_name: schedule.Name,
          group_name: "default",
        }));
      const url = process.env.REACT_APP_DELETE_SCHEDULE_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ schedules: schedulesToDelete }),
        },
        onSuccessfulModification
      );
      if (result.success) {
        setSelected([]);
      }
    } catch (error) {
      console.log("Delete action cancelled by user", error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const clearAllFilters = () => {
    setEventFilter("");
    setNumberFilter("");
    setTaskFilter("");
    setTypeFilter("");
    setTimeFilter("");
  };

  return (
    <Card style={{ minHeight: "120vh", margin: "0px", padding: "0px" }}>
      <div style={{ padding: "opx" }}>
        {ConfirmDialog}

        {/* Bulk Actions and Filters */}
        <Box m={2} display="flex" flexDirection="column" gap={2}>
          {/* Existing Filters */}
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              label="Filter by Time"
              variant="outlined"
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              placeholder="(MM/DD/YYYY HH:mm)"
            />
            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Event</InputLabel>
              <Select
                value={eventFilter}
                onChange={(e) => setEventFilter(e.target.value)}
                label="Event"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {events.map((event) => (
                  <MenuItem key={event} value={event}>
                    {event}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Number</InputLabel>
              <Select
                value={numberFilter}
                onChange={(e) => setNumberFilter(e.target.value)}
                label="Number"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {numbers.map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Task</InputLabel>
              <Select
                value={taskFilter}
                onChange={(e) => setTaskFilter(e.target.value)}
                label="Task"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {tasks.map((task) => (
                  <MenuItem key={task} value={task}>
                    {task
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ minWidth: 220 }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                label="Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip title="Clear Filters">
              <IconButton onClick={clearAllFilters} aria-label="clear">
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            pl={5}
            justifyContent={"left"}
          >
            {/* Bulk Action Buttons */}
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "20px",
                minWidth: "190px",
              }}
              variant="contained"
              color="secondary"
              onClick={handleBulkRun}
              disabled={selected.length === 0}
            >
              <BoltIcon></BoltIcon>
              Run (in 2 min)
            </Button>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "20px",
                minWidth: "190px",
              }}
              variant="contained"
              color="primary"
              onClick={handleBulkUpdateTime}
              disabled={selected.length === 0 || !bulkNewTime}
            >
              <PublishIcon></PublishIcon> Update
            </Button>
            {/* Use CustomDateTimePicker for selecting bulk new time */}
            <CustomDateTimePicker
              onConfirm={(date, tz) => {
                setBulkNewTime(date);
                setBulkNewTimezone(tz);
              }}
              edgyButton={true}
            />

            <Box
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "5px",
                minWidth: "290px",
              }}
            >
              {bulkNewTime && (
                <Typography variant="body2">
                  New selected time:{" "}
                  {translateUnixExpression(
                    bulkNewTime,
                    bulkNewTimezone || timezone
                  )}
                </Typography>
              )}
            </Box>
            {/* Delete Selected Button */}
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "auto",
                marginRight: "15px",
              }}
              variant="contained"
              color="error"
              onClick={handleDeleteSelected}
              disabled={selected.length === 0}
            >
              <DeleteIcon></DeleteIcon>
            </Button>
          </Box>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* Header Checkbox for selecting all */}

                <TableCell padding="checkbox">
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < sortedData.length
                      }
                      checked={
                        sortedData.length > 0 &&
                        selected.length === sortedData.length
                      }
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                    <Typography>{JSON.stringify(selected.length)}</Typography>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "Description"}
                    direction={
                      orderBy === "Description" ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort("Description")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Description />
                      <Typography variant="body1">
                        <strong>Schedule</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "ScheduleExpression"}
                    direction={
                      orderBy === "ScheduleExpression" ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort("ScheduleExpression")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <AccessTimeIcon />
                      <Typography variant="body1">
                        <strong>Time</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "Input"}
                    direction={orderBy === "Input" ? orderDirection : "asc"}
                    onClick={() => handleRequestSort("Input")}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <DataObjectIcon />
                      <Typography variant="body1">
                        <strong>Input</strong>
                      </Typography>
                    </Box>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Nirvana />
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((schedule, index) => (
                  <ScheduleRow
                    key={schedule.Name}
                    schedule={schedule}
                    timezone={timezone}
                    rowIndex={index}
                    selected={selected.includes(schedule.Name)}
                    onSelectChange={(checked) =>
                      handleRowSelect(schedule.Name, checked)
                    }
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
}

export default ScheduleTable;
