import React from "react";
import { useAutoLambdaWarming } from "../components/Utility/useAutoLambdaWarming";

export default function Warmupper({ children }) {
  const warmUpUrls = [
    process.env.REACT_APP_DATABASE_URL,
    process.env.REACT_APP_TASKS_URL + "/warmup_tasks",
    process.env.REACT_APP_STATUS_URL,
  ];
  const runEveryXMin = 4 * 60 * 1000;
  useAutoLambdaWarming(warmUpUrls, runEveryXMin, true);

  return <>{children}</>;
}
