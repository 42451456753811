import React, { useContext, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { MyContext } from "./Context";

const ThemeContextProvider = ({ children }) => {
  const { state } = useContext(MyContext);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: state.settings.darkMode ? "dark" : "light",
          primary: {
            main: state.settings.darkMode ? "#1976d2" : "#1976d2",
          },
          secondary: {
            main: state.settings.darkMode ? "#F24949" : "#F24949",
          },
          background: {
            default: state.settings.darkMode ? "#1f1f21" : "#f2f2f2",
          },
        },
        components: {
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  animation: "bounce 0.5s infinite",
                },
              },
            },
          },
        },
        custom: {
          rowColors: {
            odd: state.settings.darkMode ? "#242424" : "",
            even: state.settings.darkMode ? "#1f1f21" : "#fbfbfb",
          },
        },
      }),
    [state.settings.darkMode]
  );

  // Define the keyframes for bounce animation
  const bounceKeyframes = `
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-10px);
      }
      60% {
        transform: translateY(-5px);
      }
    }
  `;

  // Inject keyframes into the global style using CSSBaseline
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>{bounceKeyframes}</style>
      {children}
    </ThemeProvider>
  );
};

export default ThemeContextProvider;
