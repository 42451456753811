import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  IconButton,
} from "@mui/material";
import Thumbnail from "../Utility/Thumbnail";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
export default function Fflambda() {
  const [srtUrl, setSrtUrl] = useState(""); // Holds input field value
  const [srtUrls, setSrtUrls] = useState([]); // Stores list of added SRT URLs
  const [error, setError] = useState(null); // Error message state

  // ✅ SRT URL Validation Function
  const isValidSrtUrl = (url) => {
    const srtRegex =
      /^srt:\/\/([a-zA-Z0-9.-]+|\d{1,3}(\.\d{1,3}){3}):\d+(\/.*)?(\?.*)?$/;
    return srtRegex.test(url);
  };

  const handleAddUrl = () => {
    if (!srtUrl.trim()) {
      setError("Please enter a valid SRT stream URL.");
      return;
    }

    if (!isValidSrtUrl(srtUrl)) {
      setError(
        "Invalid SRT URL format. Must start with 'srt://' and include a port."
      );
      return;
    }

    if (srtUrls.includes(srtUrl)) {
      setError("This SRT URL has already been added.");
      return;
    }

    setSrtUrls([...srtUrls, srtUrl]); // Add URL to list
    setSrtUrl(""); // Reset input field
    setError(null); // Clear error message
  };

  const handleRemoveUrl = (index) => {
    const updatedUrls = srtUrls.filter((_, i) => i !== index);
    setSrtUrls(updatedUrls);
  };

  return (
    <div style={{ textAlign: "left", padding: "20px" }}>
      <Typography marginBottom={4} variant="h5" gutterBottom>
        FFmpeg Lambda SRT Thumbnail Generator
      </Typography>

      {/* 🔹 Input Field for SRT Link */}
      <Box display="flex" alignItems="center" gap={2} marginBottom={1}>
        <TextField
          label="Enter SRT Stream URL"
          variant="outlined"
          value={srtUrl}
          onChange={(e) => setSrtUrl(e.target.value)}
          style={{ minWidth: "500px" }}
          error={!!error}
          helperText={error}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddUrl}
          sx={{
            height: "56px",
            minWidth: "120px",
          }}
        >
          <AddIcon></AddIcon>
        </Button>
      </Box>

      {/* 🔹 Render Thumbnails for Each URL */}
      {srtUrls.length > 0 && (
        <Box display="flex" flexWrap="wrap" gap={3} marginTop={2}>
          {srtUrls.map((url, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                padding: 15,
                position: "relative",
                width: "max-content",
              }}
            >
              {/* Remove Button */}
              <IconButton
                onClick={() => handleRemoveUrl(index)}
                style={{ position: "absolute", top: 5, right: 5 }}
              >
                <DeleteIcon />
              </IconButton>

              <Typography variant="subtitle1" gutterBottom>
                {url}
              </Typography>

              <Thumbnail srtUrl={url} />
            </Paper>
          ))}
        </Box>
      )}
    </div>
  );
}
