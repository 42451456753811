import React, { useContext } from "react";
import Medialive from "./Infrastructure/MediaLive";
import { Box, Button, LinearProgress } from "@mui/material";
import { MyContext } from "../context/Context";
import LogoutIcon from "@mui/icons-material/Logout";
import ReplayIcon from "@mui/icons-material/Replay";
export default function OperatorView(props) {
  const { state, onSuccessfulModification } = useContext(MyContext);

  return (
    <div>
      {state.activeRequests > 0 ? (
        <Box sx={{ width: "100%", marginTop: "5px" }}>
          <LinearProgress />
        </Box>
      ) : (
        <div style={{ marginTop: "5px" }}></div> // Empty space when there are no active requests
      )}
      <Box style={{ width: "100%" }} display="flex" justifyContent={"right"}>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.signOut}
          sx={{ m: 2 }}
        >
          <LogoutIcon></LogoutIcon>
        </Button>
      </Box>
      <Box ml={10} mr={10} mb={10}>
        <Box display="flex" justifyContent={"left"} alignItems={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSuccessfulModification}
            sx={{ mb: 2 }}
          >
            <ReplayIcon></ReplayIcon>
          </Button>
        </Box>
        {}
        <Medialive filter="FCO" />
      </Box>
    </div>
  );
}
