import React, { useState, useContext } from "react";
import { Dialog, DialogTitle, Button, TextField, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { MyContext } from "../../context/Context";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone.js"; // Import timezone plugin
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HistoryIcon from "@mui/icons-material/History";
dayjs.extend(utc);
dayjs.extend(timezone);

function CustomDateTimePicker({ onConfirm, edgyButton = false }) {
  const { state } = useContext(MyContext);
  const defaultDate = dayjs()
    .tz(state.settings.timezone)
    .add(1, "minute")
    .set("second", 0);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [called, setCalled] = useState(false);

  const handleConfirm = () => {
    const unixTimestampMs = selectedDate.set("second", 0).valueOf(); // Ensures seconds are 00
    setCalled(true);
    onConfirm(unixTimestampMs, state.settings.timezone);
    setOpen(false);
  };

  const handleReset = () => {
    // Reset selectedDate to default value.
    setSelectedDate(defaultDate);
    onConfirm(null, state.settings.timezone);
    setCalled(false);
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      // Set seconds to 00 on date change
      setSelectedDate(newValue.set("second", 0));
    }
  };

  const buttonStyle = edgyButton
    ? {
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",
      }
    : {};

  return (
    <div>
      <Box display="flex" alignItems="center" gap={1}>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          style={buttonStyle}
        >
          <AccessTimeIcon />
        </Button>
        {called ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReset}
            style={buttonStyle}
          >
            <HistoryIcon />
          </Button>
        ) : null}
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <div
          style={{
            margin: "20px",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <DialogTitle>
            Select Date and Time in {state.settings.timezone}
          </DialogTitle>
          <Box display="flex" flexDirection="column" gap={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                ampm={false}
                label="MM/DD/YYYY HH:MM"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                minDateTime={dayjs()
                  .tz(state.settings.timezone)
                  .set("second", 0)}
              />
            </LocalizationProvider>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="contained" onClick={handleConfirm}>
              Confirm
            </Button>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}

export default CustomDateTimePicker;
