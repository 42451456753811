import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MyProvider } from "./context/Context";
import NavBar from "./components/NavBar";
import "./App.css";
import { Box, CircularProgress } from "@mui/material";
import Database from "./components/Database/Database";
import Settings from "./components/Settings/Settings";
import Schedules from "./components/Schedules/Schedules";
import Events from "./components/Events/Events";
import Infrastructure from "./components/Infrastructure/Infrastructure";
import ThemeContextProvider from "./context/ThemeContextProvider";
import Footer from "./components/Footer";
import { useAuthenticator } from "@aws-amplify/ui-react"; // Use Amplify's hook for auth
import Flows from "./components/Flows/Flows";
import Monitor from "./components/Monitor/Monitor";
import Fflambda from "./components/Fflambda/Fflambda";
import FlowManager from "./components/Flows/FlowManager";
import EventDetails from "./components/Events/EventDetails";
import { fetchAuthSession } from "aws-amplify/auth";
import OperatorView from "./components/OperatorView";
import Warmupper from "./context/Warmupper";
import GoToTopButton from "./components/Utility/GoToTopButton";

function ControlTower() {
  const { user, signOut, isLoading } = useAuthenticator(); // useAuthenticator hook to get user and signOut
  const hasListenerBeenAdded = useRef(false);
  const [isOperator, setIsOperator] = useState(null);

  useEffect(() => {
    const checkUserRole = async () => {
      try {
        const session = await fetchAuthSession();
        const groups =
          session.tokens?.accessToken?.payload["cognito:groups"] || [];

        console.log("User Groups:", groups);
        setIsOperator(groups.includes("operator"));
      } catch (error) {
        console.error("Error fetching user roles:", error);
        setIsOperator(false); // Default to false if there's an error
      }
    };

    checkUserRole();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // You could handle token refresh or session re-check here if necessary
      }
    };

    // Add the event listener for visibility changes
    if (!hasListenerBeenAdded.current) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      hasListenerBeenAdded.current = true;
    }

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (isLoading || isOperator === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <MyProvider>
      <ThemeContextProvider>
        {isOperator ? (
          <Box display="flex" flexDirection={"column"}>
            <OperatorView signOut={signOut}></OperatorView>
            <Footer userEmail={user.signInDetails.loginId} />
          </Box>
        ) : (
          <Warmupper>
            <Router>
              <div className="appContainer">
                <div className="content">
                  <NavBar signOut={signOut} />{" "}
                  {/* Passing signOut from useAuthenticator */}
                  <Routes>
                    <Route path="/" element={<Events />} />
                    <Route path="/events" element={<Events />} />
                    <Route
                      path="/events/:eventId"
                      element={<EventDetails />}
                    />{" "}
                    {/* Dynamic route */}
                    <Route path="/database" element={<Database />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/schedules" element={<Schedules />} />
                    <Route
                      path="/infrastructure"
                      element={<Infrastructure />}
                    />
                    <Route path="/monitoring" element={<Monitor />} />
                    <Route path="/fflambda" element={<Fflambda />} />
                    <Route path="/flow" element={<FlowManager />} />
                  </Routes>
                </div>
                <Footer userEmail={user.signInDetails.loginId} />
              </div>
            </Router>
            <GoToTopButton />
          </Warmupper>
        )}
      </ThemeContextProvider>
    </MyProvider>
  );
}

export default ControlTower;
