import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MyContext } from "../../context/Context";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Chip,
  Divider,
  Card,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import JSONDialog from "../Utility/JSONDialog";
import moment from "moment-timezone";
import schemas from "../Database/schemas.json";
import Flows from "../Flows/Flows";
import MixConfigs from "./MixConfigs";
import PanoramaWideAngleIcon from "@mui/icons-material/PanoramaWideAngle";
import Nirvana from "../Utility/Nirvana";
import LoaderSkeleton from "../Utility/LoaderSkeleton";

export default function EventDetails() {
  const { eventId } = useParams(); // Get event ID from URL
  const { state } = useContext(MyContext);
  const navigate = useNavigate();

  // Find the event in the context state
  const event = state.database?.events?.find((ev) => ev.uid === eventId);

  if (!event) {
    return (
      <Typography variant="h6">
        <Nirvana></Nirvana>
      </Typography>
    );
  }

  const formatValue = (key, value) => {
    let tableName = "events";
    if (schemas.tables[tableName]?.item_schema[key]?.format === "timestamp") {
      return moment
        .tz(Number(value), state.settings.timezone)
        .format("MM/DD/YYYY HH:mm");
    }
    if (schemas.tables[tableName]?.item_schema[key]?.tableReference) {
      const referencedTable =
        schemas.tables[tableName].item_schema[key].tableReference;
      const referencedItem = state.database[referencedTable]?.find(
        (item) => item.uid === value
      );
      return referencedItem ? referencedItem.alias.replace(/_/g, " ") : "N/A";
    }
    return value;
  };

  const homeTeam = state.database.tournaments
    ?.find((profile) => profile.uid === event.tournament_uid)
    ?.teams.find((team) => team.uid === event.home_team);

  const awayTeam = state.database.tournaments
    ?.find((profile) => profile.uid === event.tournament_uid)
    ?.teams.find((team) => team.uid === event.away_team);

  return (
    <Box sx={{ padding: 4 }}>
      {/* Back Button */}
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ ml: 2 }}>
          {event.alias.replace(/_/g, " ")}
        </Typography>
        <Box
          display="flex"
          ml={"50px"}
          alignItems={"center"}
          gap={"5px"}
          justifyContent={"space-between"}
        >
          <Box
            display="flex"
            alignItems={"center"}
            gap={"5px"}
            style={{ height: "20px" }}
          >
            <Typography variant="h6">{homeTeam.alias}</Typography>
            <Typography variant="h6">({homeTeam.short_code})</Typography>

            {homeTeam.logo_url === "" ? (
              <PanoramaWideAngleIcon />
            ) : (
              <img
                src={homeTeam.logo_url}
                style={{ maxWidth: "50px", maxHeight: "50px" }}
                alt="Home"
              />
            )}
          </Box>
          <Typography variant="h6">:</Typography>
          <Box display="flex" alignItems={"center"} gap={"5px"}>
            {awayTeam.logo_url === "" ? (
              <PanoramaWideAngleIcon />
            ) : (
              <img
                src={awayTeam.logo_url}
                style={{ maxWidth: "50px", maxHeight: "50px" }}
                alt="Away"
              />
            )}
            <Typography variant="h6">{awayTeam.alias}</Typography>
            <Typography variant="h6">({awayTeam.short_code})</Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent={"left"} alignItems={"center"}>
        <Box mr={10}>
          <Card>
            <Box p={3}>
              {/* Event Details */}
              <Typography variant="body1">
                <strong>Event ID:</strong> {event.uid}
              </Typography>
              <Typography variant="body1">
                <strong>Start Time:</strong>{" "}
                {formatValue("start_time", event.start_time)}
              </Typography>
              <Typography variant="body1">
                <strong>End Time:</strong>{" "}
                {formatValue("end_time", event.end_time)}
              </Typography>
              <Typography variant="body1">
                <strong>Stage:</strong> {event.stage.replace(/_/g, " ")}
              </Typography>
              <Typography variant="body1">
                <strong>Number:</strong> {event.number}
              </Typography>
              <Typography variant="body1">
                <strong>Tournament:</strong>{" "}
                {formatValue("tournament_uid", event.tournament_uid)}
              </Typography>
              <Typography variant="body1">
                <strong>Workflow Profile:</strong>{" "}
                {formatValue(
                  "workflow_profile_uid",
                  event.workflow_profile_uid
                )}
              </Typography>
              <Typography variant="body1">
                <strong>Distribution:</strong>{" "}
                {formatValue("distribution_uid", event.distribution_uid)}
              </Typography>
              {/* Configuration JSON Dialog */}
              <Box mt={2}>
                {Object.keys(event.config).length > 0 ? (
                  <JSONDialog input_json={event.config} name={event.alias} />
                ) : (
                  <Typography variant="body2" color="error">
                    No configuration available...
                  </Typography>
                )}
              </Box>
            </Box>
          </Card>
        </Box>
        <Box style={{ margin: "30px" }}>
          <MixConfigs
            config={event.config?.mix_configs || []}
            eventUid={event.uid}
          />
        </Box>
      </Box>
      <Flows selectedEventId={event.uid} config={event.config} />
    </Box>
  );
}
