import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export default function FlowEventSelector({
  events,
  selectedEventId,
  onSelectEvent,
}) {
  return (
    <FormControl
      style={{ marginLeft: "30px", minWidth: "250px", marginTop: "50px" }}
    >
      <InputLabel>Select Event</InputLabel>
      <Select
        value={selectedEventId}
        onChange={(e) => onSelectEvent(e.target.value)}
        label="Select Event"
      >
        {events
          ?.filter(
            (event) => event.config && Object.keys(event.config).length > 0
          )
          .sort((a, b) => {
            const timeDiff = b.start_time - a.start_time; // Sort by Unix timestamp (descending)
            if (timeDiff !== 0) return timeDiff; // If different, return time sorting result

            return a.alias.localeCompare(b.alias); // If timestamps are the same, sort alphabetically by alias
          })
          .map((event, index) => (
            <MenuItem key={event.uid + index} value={event.uid}>
              {event.alias}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
