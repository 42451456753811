import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import Thumbnail from "./Thumbnail";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function ThumbnailsDialog({
  srtUrls = [],
  open,
  onClose,
  ident = "",
}) {
  const [reloadKey, setReloadKey] = useState(0); // Key to force re-render

  const handleReload = () => {
    setReloadKey((prev) => prev + 1); // Increment to trigger remount
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Preview: {ident}</Typography>
          <IconButton onClick={handleReload} title="Reload All">
            <RefreshIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="flex-start"
          gap={2}
        >
          {srtUrls.length > 0 ? (
            srtUrls.map(({ routeName, srtUrl }, index) => (
              <Box
                key={`${reloadKey}-${index}`}
                maxWidth="450px"
                textAlign="center"
              >
                <Typography variant="subtitle1" gutterBottom>
                  {routeName}
                </Typography>
                <Thumbnail srtUrl={srtUrl} autoLoad={true} />
              </Box>
            ))
          ) : (
            <Box textAlign="center">
              <p>No previews available.</p>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ marginTop: "15px" }}>
        <Button onClick={onClose} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
