import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the copy icon
import Tooltip from "@mui/material/Tooltip";
import JSONDialog from "../../Utility/JSONDialog";
import FilterIcon from "@mui/icons-material/Filter";
import PublicIcon from "@mui/icons-material/Public";
import CastIcon from "@mui/icons-material/Cast";

function MixElixirDetails({ mixConfig }) {
  return (
    <Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <p>
          <strong>{mixConfig.profile.alias}</strong>
        </p>
      </Box>
      <Box display="flex" gap="10px" alignItems={"center"} height="30px">
        <CastIcon></CastIcon>
        <p>
          Destination: <strong>{mixConfig.route_name.toUpperCase()} </strong>
        </p>
      </Box>
      <Box display="flex" alignItems="center" gap="10px" height="30px">
        <PublicIcon />

        <p>
          Region: <strong>{mixConfig.profile.region}</strong>
        </p>
      </Box>
      {Array.isArray(mixConfig.profile.graphics) &&
        mixConfig.profile.graphics.map((graphic, graphicIndex) => (
          <Box
            key={graphicIndex}
            display="flex"
            gap="10px"
            height="35px"
            alignItems="center"
          >
            {/* Clickable FilterIcon that opens the URL in a new tab */}
            <Box
              onClick={() =>
                window.open(graphic.url, "_blank", "noopener,noreferrer")
              }
              sx={{ cursor: "pointer" }} // Add pointer cursor on hover
            >
              <FilterIcon />
            </Box>

            <p>
              Graphics: <strong>{graphic.alias}</strong>
            </p>
          </Box>
        ))}
    </Box>
  );
}

export default MixElixirDetails;
