import React, { useContext, useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useCronTranslator from "../Utility/useCronTranslator";
import moment from "moment-timezone";
import { MyContext } from "../../context/Context";
import JSONDialog from "../Utility/JSONDialog";
import { useTheme, styled } from "@mui/material/styles";
import DistributionsBox from "../Utility/DistributionsBox";

const ArchivedScheduleRow = ({
  schedule,
  timezone,
  rowIndex,
  selected,
  onSelectChange,
}) => {
  const { onSuccessfulModification } = useContext(MyContext);
  const translateCronExpression = useCronTranslator(timezone);
  const theme = useTheme();
  const isOdd = rowIndex % 2 !== 0;

  // Format execution time using moment
  const formattedExecutionTime = moment(Number(schedule.execution_time))
    .tz(timezone)
    .format("MM/DD/YYYY HH:mm:ss");

  return (
    <React.Fragment>
      <TableRow
        style={{
          backgroundColor: isOdd
            ? theme.custom.rowColors.odd
            : theme.custom.rowColors.even,
        }}
      >
        {/* Selection Checkbox */}
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onChange={(e) => onSelectChange(e.target.checked)}
          />
        </TableCell>
        <TableCell></TableCell>
        {/* Schedule Details */}
        <TableCell component="th" scope="row">
          {(() => {
            const [event, number, task, type, index, distribution] =
              schedule.schedule_description.split(";");
            return (
              <Box display="flex" gap="15px">
                <Chip
                  label={
                    <Typography style={{ fontWeight: "bold" }}>
                      {event}
                    </Typography>
                  }
                  size="medium"
                />
                <Chip label={number} size="medium" />
                <Chip
                  variant="outlined"
                  style={{ minWidth: "150px" }}
                  label={
                    <Typography style={{ fontWeight: "bold" }}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Typography>
                  }
                  size="medium"
                />
                <Chip
                  label={task
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  size="medium"
                  variant="outlined"
                  style={{ minWidth: "250px" }}
                />
                <Chip label={index} size="medium" variant="outlined" />
                <DistributionsBox distributions={[distribution]} />
              </Box>
            );
          })()}
        </TableCell>
        {/* Execution Time */}
        <TableCell>{formattedExecutionTime}</TableCell>
        {/* JSONDialog for Input */}
        <TableCell component="th" scope="row">
          <JSONDialog
            input_json={schedule.input}
            name={schedule.schedule_name}
            execution_time={formattedExecutionTime}
            timezone={timezone}
          />
        </TableCell>
        {/* JSONDialog for Response */}
        <TableCell component="th" scope="row">
          <JSONDialog
            input_json={schedule.response}
            name={schedule.schedule_name}
            execution_time={formattedExecutionTime}
            timezone={timezone}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ArchivedScheduleRow;
