import { Grid, Typography, Link, Paper, Box } from "@mui/material";
import React from "react";
import DnsIcon from "@mui/icons-material/Dns";

export default function RecordStreamhubConfig({ recordConfig }) {
  return (
    <div style={{ marginTop: "10px" }}>
      {recordConfig?.api_config.streamhubs.map((streamhub, index) => (
        <Box sx={{ display: "flex", gap: "5px" }}>
          <DnsIcon color={"disabled"} />
          <Link href={`https://${streamhub["host"]}`} underline="hover">
            {streamhub["host"]}
          </Link>
        </Box>
      ))}
      <br></br>
      <Typography variant="h6" index>
        Recording route: {recordConfig.source_route_name}
      </Typography>
    </div>
  );
}
