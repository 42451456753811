// DistributionsBox.jsx
import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import PropTypes from "prop-types";

const DistributionsBox = ({ distributions }) => {
  if (!distributions || distributions.length === 0) {
    return (
      <Box
        sx={{
          marginLeft: "20px",
          display: "flex",
          minWidth: "100px",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <Chip
          style={{ width: "35px" }}
          color="secondary"
          variant="contained"
          label={<Typography>?</Typography>}
          size="medium"
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginLeft: "20px",
        display: "flex",
        minWidth: "100px",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {distributions.map((distribution, idx) => (
        <Chip
          key={idx}
          style={{ width: "35px" }}
          color="primary"
          variant="contained"
          label={<Typography>{distribution}</Typography>}
          size="medium"
        />
      ))}
    </Box>
  );
};

DistributionsBox.propTypes = {
  distributions: PropTypes.arrayOf(PropTypes.string),
};

export default DistributionsBox;
