// src/utilities/DistributionEditorDialog.jsx
import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DistributionEditor from "./DistributionEditor";

const DistributionEditorDialog = ({
  open,
  onClose,
  tableName,
  currentItem,
  availableDistributions,
  onSaveSuccess,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Distributions
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DistributionEditor
          tableName={tableName}
          currentItem={currentItem}
          availableDistributions={availableDistributions}
          onSaveSuccess={(updatedItem) => {
            onSaveSuccess(updatedItem);
            onClose();
          }}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

DistributionEditorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  currentItem: PropTypes.object.isRequired,
  availableDistributions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSaveSuccess: PropTypes.func,
};

export default DistributionEditorDialog;
