import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MovieIcon from "@mui/icons-material/Movie";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip
import Thumbnail from "../Utility/Thumbnail";

export default function RouteDestination({
  destination,
  host,
  routeName,
  gateway,
  triggerReload,
  destState,
}) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [loading, setLoading] = useState(false); // New state for tracking loading
  const [editMode, setEditMode] = useState(false);
  const [editedDestination, setEditedDestination] = useState({
    srt_name: destination.name,
    srt_port: destination.port,
    srt_address: destination.address,
    srt_connection_limit: destination.srtConnectionLimit ?? 4,
    srt_passphrase: destination.srtPassPhrase,
    srt_mode: destination.mode,
    srt_latency: destination.srtLatency,
    action: "start",
  });
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const handleChange = (prop) => (event) => {
    setEditedDestination({ ...editedDestination, [prop]: event.target.value });
  };

  const openVlc = (streamUrl) => {
    window.location.href = streamUrl;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await requestConfirm("Are you sure you want to update this destination");
      let url =
        process.env.REACT_APP_TASKS_URL +
        "/components/exchange/gateway/update_gateways_route_destination";
      const { success, data, error } = await makeApiRequest(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: {
            routes: [
              {
                route_name_to_update: routeName,
                destinations_with_update: [editedDestination],
              },
            ],
            gateways: [gateway],
          },
        }),
      });
      if (success) {
        setTimeout(() => {
          triggerReload();
          setEditMode(false);
          setLoading(false);
        }, 3000);
      }
      setLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      console.log("Action cancelled by user");
      setLoading(false);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div>
      {ConfirmDialog}
      <Box display="flex" justifyContent={"left"} alignItems="top" gap="20px">
        <Box>
          <Thumbnail
            disable={
              destState ==
                ("connected" && destination.srtConnectionLimit == "1") ||
              destination.mode == "caller"
            }
            srtUrl={`srt://${host}:${destination.port}?passphrase=${destination.srtPassPhrase}&mode=caller`}
          ></Thumbnail>
        </Box>
        <Box sx={{ margin: 2 }}>
          <Typography variant="h6" gutterBottom>
            Destination: {destination.name}
            <IconButton
              style={{ marginLeft: "10px" }}
              onClick={() => setEditMode(!editMode)}
              size="medium"
            >
              {editMode ? (
                <CloseIcon className="icon" />
              ) : (
                <EditIcon className="icon" />
              )}
            </IconButton>
            {loading && <LinearProgress />}
          </Typography>

          {editMode ? (
            <>
              <Box style={{ width: "300px" }}>
                <TextField
                  label="Port"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                  value={editedDestination.srt_port}
                  onChange={handleChange("srt_port")}
                />
                <TextField
                  label="Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                  value={editedDestination.srt_address}
                  onChange={handleChange("srt_address")}
                />
                <TextField
                  label="Connection Limit"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                  value={editedDestination.srt_connection_limit}
                  onChange={handleChange("srt_connection_limit")}
                />
                <TextField
                  label="Passphrase"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                  value={editedDestination.srt_passphrase}
                  onChange={handleChange("srt_passphrase")}
                />
                <TextField
                  label="Latency"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                  value={editedDestination.srt_latency}
                  onChange={handleChange("srt_latency")}
                />
                <FormControl fullWidth margin="dense" size="small">
                  <InputLabel id="srt-mode-label">Mode</InputLabel>
                  <Select
                    labelId="srt-mode-label"
                    id="srt-mode"
                    value={editedDestination.srt_mode}
                    label="Mode"
                    onChange={handleChange("srt_mode")}
                  >
                    <MenuItem value="caller">Caller</MenuItem>
                    <MenuItem value="listener">Listener</MenuItem>
                  </Select>
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="srt-mode-label">Action</InputLabel>
                    <Select
                      labelId="srt-mode-label"
                      id="action"
                      value={editedDestination.action}
                      label="Action"
                      onChange={handleChange("action")}
                    >
                      <MenuItem value="start">Start</MenuItem>
                      <MenuItem value="stop">Stop</MenuItem>
                    </Select>
                  </FormControl>
                </FormControl>
              </Box>
              <Button
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Submit Changes
              </Button>
            </>
          ) : (
            <>
              <Typography>Port: {destination.port}</Typography>
              <Typography>Address: {destination.address}</Typography>
              <Typography>
                Connection Limit: {destination.srtConnectionLimit}
              </Typography>
              <Typography>Passphrase: {destination.srtPassPhrase}</Typography>
              <Typography>Mode: {destination.mode}</Typography>
              <Typography>Latency: {destination.srtLatency}</Typography>
              {destination.mode == "listener" ? (
                <Box
                  style={{ display: "flex", gap: "30px", alignItems: "center" }}
                >
                  <Typography>
                    Client Link:{" "}
                    {`srt://${host}:${destination.port}?passphrase=${destination.srtPassPhrase}`}
                  </Typography>

                  {/* Copy to Clipboard Button */}
                  <Tooltip title="Copy SRT URL" arrow>
                    <IconButton
                      onClick={() =>
                        copyToClipboard(
                          `srt://${host}:${destination.port}?passphrase=${destination.srtPassPhrase}`
                        )
                      }
                      size="small"
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  {/* Copy ffplay Command Button */}
                  <Tooltip title="Copy ffplay Command" arrow>
                    <IconButton
                      onClick={() =>
                        copyToClipboard(
                          `ffplay srt://${host}:${destination.port}?passphrase=${destination.srtPassPhrase}`
                        )
                      }
                      size="small"
                    >
                      <MovieIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : null}
            </>
          )}
        </Box>
      </Box>
    </div>
  );
}
