import React, { useState, useContext, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import { Button, Paper } from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import PromexDetails from "./PromexDetails";
import { HubSharp } from "@mui/icons-material";
import DistributionsBox from "../Utility/DistributionsBox";
import { useTheme, styled } from "@mui/material/styles";

export default function Promex({ promex, index }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();
  const [loading, setLoading] = useState(false); // New state for tracking loading
  const isOdd = index % 2 !== 0;
  const theme = useTheme();

  const handleStart = async (promexs) => {
    try {
      await requestConfirm("Are you sure you want to start this instance?");
      let url = process.env.REACT_APP_START_PROMEXS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promexs: promexs,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const handleStop = async (promexs) => {
    try {
      await requestConfirm("Are you sure you want to stop this instance?");
      let url = process.env.REACT_APP_STOP_PROMEXS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promexs: promexs,
          }),
        },
        onSuccessfulModification
      );
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const promexStatus = state.status.promexs?.find((promex_status) =>
    promex_status.InstanceStatuses.some(
      (instance) => instance.InstanceId === promex["aws_instance_id"]
    )
  );
  const instanceState = promexStatus ? (
    <Typography>
      {" "}
      {promexStatus.InstanceStatuses[0].InstanceState.Name}{" "}
    </Typography>
  ) : (
    <LinearProgress style={{ width: "50px" }} />
  );
  return (
    <div>
      {ConfirmDialog}
      <Accordion
        key={index}
        style={{
          backgroundColor: isOdd
            ? theme.custom.rowColors.odd
            : theme.custom.rowColors.even,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <ComputerIcon
              color={
                promexStatus
                  ? promexStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                    ? "primary"
                    : "secondary"
                  : "action"
              }
            />
            <Typography style={{ minWidth: "350px" }}>
              {promex["name"]}
            </Typography>
            <Box sx={{ display: "flex" }}>{instanceState}</Box>
            {promex["distributions"] && (
              <DistributionsBox distributions={promex["distributions"]} />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                marginLeft: "10px",
                borderTopRightRadius: "0px",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleStart([promex])}
            >
              Start
            </Button>
            <Button
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopLeftRadius: "0px",
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleStop([promex])}
              disabled={
                !(
                  promexStatus &&
                  promexStatus.InstanceStatuses[0].InstanceState.Name ===
                    "running"
                )
              }
            >
              Stop
            </Button>
          </div>
          <Paper style={{ padding: "20px", marginBottom: 20 }}>
            <PromexDetails promex={promex}> </PromexDetails>
            {loading && <LinearProgress />}{" "}
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
